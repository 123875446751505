import React, { useEffect, useRef, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import LoadingView from '../loading/LoadingView';

import { timeSince, getPreviewImage,  getImagerForTops, getNewsBackgroundCss, getNewsBackgroundHref, getArticleRoute, getProfileUrl, LocalizeText } from '../../Globals';

import { NewsComposer } from '../../packets/NewsComposer';
import { TweetsComposer } from '../../packets/TweetsComposer';
import { CreateTweetComposer } from '../../packets/CreateTweetComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';
import { TopsComposer } from '../../packets/TopsComposer';
import { NotificationManager } from 'react-notifications';

const DashboardView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [effect, setEffect] = useState(30);
  const [background, setBackground] = useState("https://files.habboemotion.com/resources/images/website_backgrounds/bg_blue.gif");
  const [news, setNews] = useState<any>(null);
  const [tweets, setTweets] = useState<any>(null)
  const [postCount, setPostCount] = useState(0);
  const [postContent, setPostContent] = useState("");
  const [lastUsers, setLastUsers] = useState<any>(null);
  

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setPostCount(postContent.length)
  }, [postContent])

  useEffect(() => {
    if(props.account === null) navigate("/index", { replace: true });
  }, [props]);

  var isLoaded = false;
  useEffect(() => {
    if(isLoaded) return;
    isLoaded = true;
    document.title = "Dashboard | " + "Habbo"

    sendMessage(JSON.stringify(new NewsComposer()))
    sendMessage(JSON.stringify(new TweetsComposer()))
    sendMessage(JSON.stringify(new TopsComposer()))

    if(props.account === null) navigate("/index", { replace: true });
    if(window.localStorage.getItem("kubboch-sto") === null || window.sessionStorage.getItem("kubboch-stoc") === null) navigate("/index", { replace: true });
  }, [])

  
  useEffect(() => {
    if (lastMessage !== null) {
        var message = JSON.parse(lastMessage.data);

        if(message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
        if(message.header === "tweets" && message.data !== null) setTweets(JSON.parse(message.data.data));
        if(message.header === "createTweetResponse" && message.data !== null){
            if(message.data.data === "Tweet no valido") NotificationManager.error(LocalizeText(props.texts, 'tweet.error.invalid'), "Error")
            else if(message.data.data === "No intentes hacer publicidad!") NotificationManager.error(LocalizeText(props.texts, 'tweet.error.publi'), "Error")
            else if(message.data.data === "cooldown") NotificationManager.error(LocalizeText(props.texts, 'tweet.coldown'), "Error")
            else NotificationManager.success(LocalizeText(props.texts, 'tweet.success'), "OK")
        }
        if(message.header === "tweetResponse" && message.data !== null){
            if(message.data.data === "Error") NotificationManager.error(LocalizeText(props.texts, 'tweet.error'), "Error")
            else if(message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'tweet.error'), "Error")
            else NotificationManager.success(LocalizeText(props.texts, 'tweet.delete.success'), "OK")
        }

        if(message.header === "usersTops" && message.data !== null){
            setLastUsers(JSON.parse(message.data.lastUsers));
        }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if(news !== null && tweets !== null) setLoading(false)
  }, [news, tweets])

  const changeEfect = (effect) => {
    window.localStorage.setItem(global.localStorageMeEffect, effect);
    setEffect(effect);
  }

  const changeBackground = (bg) => {
    window.localStorage.setItem(global.localStorageMeBackground, bg);
    setBackground(bg);
  }

  const getPreviewEffect = (effect) => global.imagerUrl+props.account.look+"&direction=3&head_direction=3&effect="+effect+"&img_format=gif";
  const getImagerLook = (look) => global.imagerUrl+look+"&direction=3&head_direction=3&size=l&effect="+effect+"&img_format=gif";
  
  const createTweet = () => {
    if(postContent.length > 250){
        NotificationManager.error(LocalizeText(props.texts, 'tweet.length'), "Error")
        return;
    }
    
    if(postContent === ""){
        NotificationManager.error(LocalizeText(props.texts, 'tweet.empty'), "Error");
        return;
    }

    sendMessage(JSON.stringify(new CreateTweetComposer(postContent)))
    sendMessage(JSON.stringify(new TweetsComposer()))
    setPostContent("");
  }

  const deleteTweet = (id) => {
    sendMessage(JSON.stringify(new DeleteTweetComposer(id)))
    sendMessage(JSON.stringify(new TweetsComposer()))
  }

  // lariesse 

  const [modalOpen, setModalOpen] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    if (modalContentRef.current && modalContentRef.current.contains(e.target as Node)) {
      return;
    }

    setModalOpen(false);
  };

  //
  const location = useLocation();


  useEffect(() => {
    if (location.pathname === "/play") {
        setclientInit(true);
        setVisibility("visible");
    }
    else setVisibility("hidden");

}, [location]);

const getSrcIframe = (sso) => "https://nitro.habbo.cm/index.html?sso=" + sso;

const [clientInit, setclientInit] = useState(false);
const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible")

  return (
    <>
    {props.account != null &&
      <>
        {loading ? <LoadingView /> : (
          <>
            <div className='max-w-5xl mx-auto grid grid-cols-12 px-2 gap-y-4 animate__animated animate__fadeIn animate__slower mt-5 mb-8'>
              <div className="col-span-12">
                <div className="flex items-center bg-tertiary rounded-lg">
                  <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                    <div className="cmCommentIcon"></div>
                    <span>Announcement</span>
                  </div>
                  <div className="p-3.5 text-sm text-white text-opacity-70">
                    Join Discord to get updates.
                    <a href="https://discord.gg/habbocm" target='__blank' className='ml-1'>
                      discord.gg/habbocm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-span-12">
                <div className="p-2.5 bg-tertiary rounded-lg flex flex-col">
                  <div className="!h-24 w-full cmBPicture !bg-center relative rounded-lg flex justify-center items-center">
                    <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
                    <div className="absolute right-4">
                      <Link to={"/play"}>
                        <div className="py-0 px-20 mt-0 text-xl text-center text-white w-full bg-[#01a41b] border-t-[10px] rounded-t-lg border-t-[#03c304] border-b-[10px] rounded-b-lg border-b-[#01981e]">
                          Login
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row items-center justify-between px-4">
                    <div className="flex items-center gap-3">
                      <div className="cmAvatar relative hidden lg:block">
                        <div className="w-[64px] h-[110px] cmDropshadow absolute left-0 flex items-center justify-center rounded-full">
                          <img className='relative -top-20 left-3' src={`https://nitro-imager.habbo.cm/?figure=${props.account.look}&gesture=sml&direction=2&size=m&action=wave&head_direction=2`} />
                        </div>
                        <div className="cmPlate"></div>
                      </div>
                      <div className="flex items-center gap-1.5 text-sm text-white text-opacity-70 mt-2 ml-4 font-light">
                        <div className="cmNIcon"></div>
                        <span>{props.account.username}</span>
                      </div>
                      <div className="flex items-center gap-1.5 text-sm text-white text-opacity-70 mt-2 ml-4 font-light">
                        <div className="cmCommentIcon"></div>
                        <span>{props.account.motto}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-3 mt-2">
                      <div className="flex items-center gap-4 p-2 rounded-lg bg-[#ffc000] text-sm">
                        <div className="cmCreditsIcon"></div>
                        {props.account.credits}
                      </div>
                      <div className="flex items-center gap-4 p-2 rounded-lg bg-[#4aa78f] text-sm">
                        <div className="cmDiamondsIcon"></div>
                        {props.account.diamonds}
                      </div>
                      <div className="flex items-center gap-4 p-2 rounded-lg bg-[#21b940] text-sm">
                        <div className="cmCurrencyIcon"></div>
                        {props.account.currency}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 p-2 bg-tertiary flex items-center rounded-lg justify-between gap-4">
                <div className="p-3 flex items-center w-[74px] h-[74px] justify-center bg-primary rounded-lg relative">
                  <div className="w-[64px] h-[110px] cmDropshadow absolute left-0 mx-auto flex items-center justify-center rounded-full">
                    <img className='relative top-4 left-2' src={`https://nitro-imager.habbo.cm/?figure=${props.account.look}&gesture=sml&direction=2&size=m&action=wave&head_direction=2&headonly=1`} />
                  </div>
                </div>
                <div className="flex grow relative top-3">
                  <textarea value={postContent} onChange={e => setPostContent(e.target.value)} name="" id="" placeholder={`Hi, ${props.account.username}!, What do you think?`} className=' text-white text-opacity-50 font-light'></textarea>
                </div>
                <div onClick={() => createTweet()} className="bg-[#01a41b] py-1.5 px-14 text-white rounded-lg">
                  Share
                </div>
              </div>
              <div className="col-span-12 flex flex-col gap-3">
                <p className='text-xl font-bold text-white text-opacity-90 px-4'>Community Feed</p>
                {tweets !== null &&
                  <>
                    {tweets.slice(0, 5).map((tweet) =>
                      <div key={tweet.id} className="p-2 bg-tertiary rounded-lg flex flex-col">
                        <div className="col-span-12 bg-secondary flex items-center rounded-lg justify-between gap-4 max-h-[82px]">
                          <div className="p-3 m-1 flex items-center w-[74px] h-[74px] justify-center bg-tertiary rounded-lg relative">
                            <div className="w-[64px] h-[110px] cmDropshadow absolute left-0 mx-auto flex items-center justify-center rounded-full">
                              <img className='relative top-4 left-2' src={`https://nitro-imager.habbo.cm/?figure=${tweet.look}&gesture=sml&direction=2&size=m&action=wave&head_direction=2&headonly=1`} />
                            </div>
                          </div>
                          <div className="flex grow relative items-center overflow-auto text-white text-opacity-70 font-light">
                            {tweet.username}: {tweet.tweetText}
                          </div>
                          <div className="flex items-center gap-4 font-light text-white text-opacity-70 mr-3 text-sm">
                            <div className="h-[82px] w-0.5 bg-tertiary"></div>
                            <p>{LocalizeText(props.texts, 'tweet.date')} {timeSince(tweet.date)}</p>
                          </div>
                        </div>
                      </div>
                    )}

                  </>
                }

              </div>
              <div className="col-span-12 flex flex-col gap-3">
                <p className='text-xl font-bold text-white text-opacity-90 px-4'>News</p>
                {news.length !== 0 &&
                  <div className="grid grid-cols-12 gap-2">
                    {news[0] != null &&
                      <Link to={getArticleRoute(news[0].id, news[0].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                        <div className="flex items-center justify-between">
                          <p className='text-white text-opacity-80'>{news[0].title}</p>
                        </div>
                        <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[0].header)})` }}></div>
                        <p className='text-white text-opacity-60 text-xs'>{news[0].shortStory}</p>
                        <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                          <p>Read All!</p>
                          <p>by {news[0].author.username}</p>
                        </div>
                      </Link>
                    }
                    {news[1] != null &&
                      <Link to={getArticleRoute(news[1].id, news[1].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                        <div className="flex items-center justify-between">
                          <p className='text-white text-opacity-80'>{news[1].title}</p>
                        </div>
                        <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[1].header)})` }}></div>
                        <p className='text-white text-opacity-60 text-xs'>{news[1].shortStory}</p>
                        <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                          <p>Read All!</p>
                          <p>by {news[1].author.username}</p>
                        </div>
                      </Link>
                    }
                    {news[2] != null &&
                      <Link to={getArticleRoute(news[2].id, news[2].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                        <div className="flex items-center justify-between">
                          <p className='text-white text-opacity-80'>{news[2].title}</p>
                        </div>
                        <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[2].header)})` }} ></div>
                        <p className='text-white text-opacity-60 text-xs'>{news[2].shortStory}</p>
                        <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                          <p>Read All!</p>
                          <p>by {news[2].author.username}</p>
                        </div>
                      </Link>
                    }
                  </div>
                }
              </div>
            </div>
            {props.account !== null && clientInit &&
                <>
                    {visibility === "visible" && <Link className='back-button btn-sm text-center' to="/me">{LocalizeText(props.texts, 'play.home.btn')}</Link>}

                    <div style={{overflow: "hidden"}}>
                        <iframe allow="
                            accelerometer *; 
                            ambient-light-sensor *; 
                            autoplay *; 
                            camera *; 
                            clipboard-read *; 
                            clipboard-write *; 
                            encrypted-media *; 
                            fullscreen *; 
                            geolocation *; 
                            gyroscope *; 
                            magnetometer *; 
                            microphone *; 
                            midi *; 
                            payment *; 
                            picture-in-picture *; 
                            screen-wake-lock *; 
                            speaker *; 
                            sync-xhr *; 
                            usb *; 
                            web-share *; 
                            vibrate *; 
                            vr *"
                        className='animate__animated animate__fadeIn animate__slower' 
                        src={getSrcIframe(window.localStorage.getItem("kubboch-sto"))} 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            width: "100vw", 
                            height: "100vh", 
                            border: "none", 
                            margin: 0, 
                            padding: 0, 
                            overflow: "hidden !important", 
                            zIndex: 999999, 
                            visibility: visibility
                            
                         }}
                        >
                        </iframe>
                    </div>
                    </>
            }
          </>
        )}
      </>
    }
  </>
  )
}

export default DashboardView
