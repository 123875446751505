import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import Draggable from 'react-draggable';
import { getPreviewImage, getImagerForProfile, timeSince, getBadgeUrl, getImagerForTops, getImagerForTopsProfile, LocalizeText } from '../../Globals';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Sticker from './Sticker';
import Widget from './Widget';
import Note from './Note';

import { NotificationManager } from 'react-notifications';

import { GetProfileComposer } from '../../packets/GetProfileComposer';
import { UpdateProfileComposer } from '../../packets/UpdateProfileComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';

import LoadingView from '../loading/LoadingView';



const ProfileView = (props, { match }) => {
    const [loading, setLoading] = useState(true);

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const navigate = useNavigate();
    const [badges, setBadges] = useState<any>(null);
    const [friends, setFriends] = useState<any>(null);
    const params = useParams();

    const [account, setAccount] = useState<any>(null);
    const [lastRegister, setLastRegister] = useState<any>(null);





    const getParamName = (param) => {
        if (param === null) return "no";
        else return param;
    }

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        if (params === null || params.name === null) navigate("/me", { replace: true })
        sendMessage(JSON.stringify(new GetProfileComposer(getParamName(params.name))))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "profileData" && message.data !== null) {
                if (message.data.data !== null && message.data.data === "Este usuario no existe") navigate("/me", { replace: true })
                else {
                    setAccount(JSON.parse(message.data.account));
                    setBadges(JSON.parse(message.data.badges));
                    setFriends(JSON.parse(message.data.friends));
                    setLastRegister(message.data.lastRegister);
                    if (message.data.style !== null) {
                        const style = JSON.parse(message.data.style);
                    }
                    setLoading(false);
                }
            }

        }
    }, [lastMessage, setMessageHistory]);


    const profileLink = (name) => {
        setLoading(true);
        sendMessage(JSON.stringify(new GetProfileComposer(name)))
        navigate("/profile/" + name, { replace: true })
    }


    
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }



    return (
        <>
            {loading ? <LoadingView /> : (
                <>
                    <div className='animate__animated text-white gap-2 max-w-5xl w-full grid grid-cols-12 mx-auto animate__fadeIn animate__slower mt-5'>
                        <div className="col-span-12">
                            <div className="flex items-center bg-tertiary rounded-lg">
                                <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                                    <div className="cmCommentIcon"></div>
                                    <span>Announcement</span>
                                </div>
                                <div className="p-3.5 text-sm text-white text-opacity-70">
                                    Join Discord to get updates.
                                    <a href="https://discord.gg/habbocm" target='__blank' className='ml-1'>
                                        discord.gg/habbocm
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="p-2.5 bg-tertiary rounded-lg flex flex-col col-span-12">
                            <div className="!h-24 w-full cmBPicture !bg-center relative rounded-lg flex items-center">
                                <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
                                <div className="absolute ml-[7.5rem]">
                                    <p className='text-xl font-bold text-white text-opacity-90 px-4'>{account.username}'s Profile</p>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row items-center justify-between px-4">
                                <div className="flex items-center gap-3">
                                    <div className="cmAvatar relative hidden lg:block">
                                        <div className="w-[64px] h-[110px] cmDropshadow absolute left-0 flex items-center justify-center rounded-full">
                                            <img className='relative -top-20 left-3' src={`https://nitro-imager.habbo.cm/?figure=${account.look}&gesture=sml&direction=2&size=m&action=wave&head_direction=2`} />
                                        </div>
                                        <div className="cmPlate"></div>
                                    </div>
                                    <div className="flex items-center gap-1.5 text-sm text-white text-opacity-70 mt-2 ml-4 font-light">
                                        <div className="cmNIcon"></div>
                                        <span>{account.username}</span>
                                    </div>
                                    <div className="flex items-center gap-1.5 text-sm text-white text-opacity-70 mt-2 ml-4 font-light">
                                        <div className="cmCommentIcon"></div>
                                        <span>{account.motto}</span>
                                    </div>
                                </div>
                                <div className="flex items-center gap-3 mt-2 text-sm text-opacity-60 text-white">
                                    {LocalizeText(props.texts, 'profile.last.register')} {formatDate(new Date(lastRegister * 1000))}
                                </div>
                            </div>
                        </div>
                        <div className="p-3 bg-tertiary rounded-lg col-span-12 text-white text-opacity-60 text-lg">
                            Badges
                        </div>
                        <div className="p-5 bg-tertiary rounded-lg col-span-12 flex items-center gap-2 flex-wrap">
                            {badges === null || badges.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.badges')}</div></div> : (
                                    <>
                                        {badges.map((badge) =>
                                            <div className="min-w-[54px] max-w-[54px] min-h-[54px] max-h-[54px] flex items-center justify-center  h-full bg-secondary rounded-lg p-2">
                                                <div style={{ background: getPreviewImage(getBadgeUrl(badge)), backgroundPosition: "center", backgroundRepeat: "no-repeat" }} className="w-full h-full"></div>
                                            </div>
                                        )}
                                    </>
                                )}
                        </div>
                        <div className="p-3 bg-tertiary rounded-lg col-span-12 text-white text-opacity-60 text-lg">
                            Friends
                        </div>
                        <div className="col-span-12 grid grid-cols-12 gap-3">
                            {friends === null || friends.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.friends')}</div></div> : (
                                    <>
                                        {friends.map((friend) =>
                                            <>
                            <button key={friend.id} onClick={() => profileLink(friend.username)} className="col-span-12 md:col-span-4 max-h-20 h-20 flex bg-tertiary rounded-lg border-r-[4px] border-r-primary" >
                                <div className="w-[128px] overflow-hidden h-full bg-primary rounded-tl-lg rounded-bl-lg">
                                <img className='relative -top-12' src={`https://nitro-imager.habbo.cm/?figure=${friend.look}&size=l&direction=2&head_direction=3&gesture=sml`} alt="" />
                                </div>
                                <div className="flex flex-col p-3 truncate">
                                <p className='text-white text-lg'>{friend.username}</p>
                                </div>
                            </button>
                                            </>
                                        )}
                                    </>
                                )}
                        </div>
                    </div>

                </>
            )}
        </>
    )
}


export default ProfileView
