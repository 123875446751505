import React, {useEffect} from 'react'

const TermsView = () => {

  return (
    <div className="container animate__animated animate__fadeIn animate__slower">
        <div className="row">
            <div className="card primary-card text-white mt-5" style={{marginBottom: "20px", whiteSpace: "pre-line"}}>
                <div className="card-body">
                <h4>Nutzungsbedingungen</h4>
                    <p></p><p><strong>habbo.cm - Terms of Service</strong></p>
                    <p><strong>Introduction</strong></p>
                    <p>BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF SERVICE AND OUR <a href="../../help/2-poltica-de-privacidad">PRIVACY POLICY</a>. IF YOU DO NOT AGREE, YOU DO NOT HAVE PERMISSION TO INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. PERMISSION TO USE THE SERVICE IS VOID WHERE PROHIBITED.</p>
                    <p>The terms of this agreement (“<strong>Terms of Service</strong>” or “<strong>Terms</strong>”) govern the relationship between you and Kubbo (hereinafter “<strong>Kubbo</strong>” or “<strong>Us</strong>” or “<strong>We</strong>”) regarding your use of Kubbo Hotel located at <a href="https://habbo.cm/">www.habbo.cm</a> or our other sites or services that post these Terms (the “<strong>Service(s)</strong>”), whether accessed by computer, mobile phone or other device (each, a “<strong>Device</strong>”). Use of the Service is also governed by the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>, incorporated herein by reference, and you consent to our practices as explained there. You further agree that we may change, alter, or modify the settings or configurations on your Device in order to allow for or optimize your use of the Service. In addition, some of our Service products or services may be made available subject to certain additional or different terms and/or conditions that apply to them (in each such instance, and collectively, “<strong>Additional Terms</strong>”), which will supplement these Terms.</p>
                    <p>Before accessing or using the Service, you must agree to these Terms and the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>and register an account on the Service (an “<strong>Account</strong>”). By registering for an Account or otherwise using the Service, you represent that you understand and accept these Terms and the Service’s<a href="../../help/2-poltica-de-privacidad"> Privacy Policy</a>. <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>. The Service may exist on, or in connection with, a number of third-party platforms (e.g., Facebook). Please be aware that your activities on these platforms may also be subject to the terms of service and/or privacy policies for that platform, and we are not responsible for these third parties.</p>
                    <p>Kubbo reserves the right to prospectively revise these Terms at any time (meaning the old terms continue to apply to your use that predates the changes). If we do, we will post the modified Terms on the Service and indicate the date of most the recent change above, and you agree that this will be sufficient notice to you that new terms apply and your continued use indicates your agreement to the new terms in connection with that ongoing use.</p>
                    <p>&nbsp;</p>
                    <p><strong>1. LICENSE</strong></p>
                    <p>The Service contains a variety of: (i) materials and other items relating to Kubbo and its products and services, and similar items from our licensors and other third parties, including all layout, information, text, data, files, images, scripts, designs, graphics, button icons, instructions, illustrations, photographs, audio clips, music, sounds, pictures, videos, advertising copy, URLs, technology, software, communications, interactive features, the “look and feel” of the Service, and the compilation, assembly, and arrangement of the materials of the Service and any and all copyrightable material (including source and object code); (ii) trademarks, logos, trade names, service marks, and trade identities of various parties, including those of Kubbo(collectively, “<strong>Trademarks</strong>”); and (iii) other forms of intellectual property (all of the foregoing, collectively “<strong>Content</strong>”). Unless otherwise specified in writing, all Content is owned, controlled, or licensed by Kubbo or its licensors. All Content is copyrighted under copyright laws and/or similar laws protecting it from unauthorized use. The Trademarks, including but not limited to <em>Kubbo</em>, its logos, and <em>Kubbo</em> and its logos, may not be used without Kubbo’s express written permission. You agree not to copy, redistribute, publish or otherwise exploit any Content in violation of the intellectual property rights of Kubbo or any other third party.</p>
                    <p>Subject to your strict compliance with these Terms, Kubbo grants you a limited, non-exclusive, revocable, non-assignable, personal, and non-transferable license to (i) download (temporary storage only), display, view, use, play, and/or print one copy of the Content (excluding source and object code in raw form or otherwise, other than as made available to access and use to enable display and functionality) on a Device for your personal, non-commercial use only, and (ii) to use certain Content that we may from time to time make available on the Service explicitly for you for use as part of your User-Generated Content (defined below) (“<strong>Kubbo Licensed Elements</strong>”), but only for such purposes as may be explicitly stated at the time that the Kubbo Licensed Elements are made available on the Service; but we and our licensors and certain other third parties, as the case may be, retain ownership of such KubboLicensed Elements. The foregoing limited license (i) does not give you any ownership of, or any other intellectual property interest in, any Content, and (ii) may be immediately suspended or terminated for any reason, in Kubbo’s sole discretion, and without advance notice or liability. In some instances, we may permit you to have greater access to and use of Content and/or KubboLicensed Elements, subject to Additional Terms.</p>
                    <p>&nbsp;</p>
                    <p><strong>2. IN-GAME CURRENCIES AND GOODS</strong></p>
                    <p>The Service may include virtual, in-game currency (“<strong>Virtual Currency</strong>”), such as credits, coins, cash, or points (e.g., Kubbo coins and diamonds), that may be purchased with “real world” money or obtained through interactions with a third-party service. Virtual Currency may be used to purchase virtual, in-game digital items (“<strong>Virtual Goods</strong>”). Your purchase or award of Virtual Currency and Virtual Goods are merely a grant by us to a limited, non-exclusive, revocable, non-assignable, personal, and non-transferable right to use the Virtual Currency and Virtual Goods as part of the Service under these Terms and any applicable Additional Terms we provide at the time. Accordingly, you have no other interest, including no property, proprietary, intellectual property, ownership, or monetary interest, in your Virtual Currency and Virtual Goods, which remain our Content and property. We reserve the absolute right, at any time and in our sole discretion, to manage, regulate, control, modify or eliminate Virtual Currency and/or Virtual Goods.</p>
                    <p>Virtual Currency or Virtual Goods cannot be redeemed by you for “real world” money, goods, or other items of monetary value from any party. Transfers of Virtual Currency or Virtual Goods by you outside of what we permit on the Service are strictly prohibited. This means you may not buy or sell Virtual Currency or Virtual Goods for “real world” money or otherwise exchange items for value outside of the Service.</p>
                    <p>You also agree that all sales of Virtual Currency and Virtual Goods are final unless otherwise detailed in the applicable Additional Terms. No refunds will be given by Kubbo. Kubbo has the absolute right to manage, regulate, control, modify, and/or eliminate Virtual Currency and/or Virtual Goods as it sees fit in its sole discretion, including the right to terminate or suspend your account or discontinue its Service (in whole or in part) for any reason, and Kubbo shall have no liability to you or anyone for the exercise of such rights.</p>
                    <p>&nbsp;</p>
                    <p><strong>3. CONTENT CREATED BY YOU, USER-GENERATED CONTENT</strong></p>
                    <p>It is possible for you contribute to the Service through the submission of Content you create or contribute to the creation of (“<strong>User-Generated Content</strong>”). User-Generated Content, includes without limitation, various kinds of content that are submitted by users (for example, wall posts, messages, reviews, and actions with others end users), as well as any feedback, comments, or suggestions that users you provide to us regarding the Service.</p>
                    <p>You will retain whatever ownership you may have in your User-Generated Content, but you hereby irrevocably grant to Kubbo a non-exclusive, worldwide, royalty-free, sublicenseable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, perform and otherwise exploit in any manner whatsoever the User-Generated Content, or any portion thereof, you submit to the Service or otherwise to Kubbo, by any and all means, and in all forms of media, now known or hereafter developed, and to advertise, market and promote the same. To the extent permitted by applicable laws, you hereby waive any moral rights or rights of publicity or privacy you may have in or in connection with such User-Generated Content.</p>
                    <p>You represent that all User-Generated Content that you submit to the Service is wholly original to you and you exclusively own the rights to such User-Generated Content, and/or you have all necessary authority to grant all of the rights and licenses to Kubbo under these Terms and/or any applicable Additional Terms, and that Kubbo and its licensees may exercise those rights without Kubbo or its licensees or successors incurring any third-party obligations or liability.</p>
                    <p>Kubbo reserves the right to control and operate our Service in the manner we deem appropriate. In connection with doing so, we may monitor User-Generated Content and your activities in connection with the Service, but cannot monitor or prescreen all of the User-Generated Content and do not attempt to do so. We have the right, but not the obligation, to edit, refuse to post, or remove any User-Generated Content posted on the Service that we deem objectionable or think violates these Terms and any applicable Additional Terms, or the spirit of these Terms and any applicable Additional Terms, in our sole discretion and determination. If you encounter something you find objectionable and in violation of these Terms, you can bring it to our attention by contacting us <a href="https://help.habbo.cm/anonymous_requests/new">here</a>.</p>
                    <p>We do not assume any responsibility or liability for User-Generated Content that is generated by users of the Service. You bear the entire risk of the completeness, accuracy or usefulness of any User-Generated Content found in connection with the Service. You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service. Kubbo reserves the right, but has no obligation, to become involved in any way with these disputes. You will fully cooperate with Kubbo to investigate any suspected unlawful, fraudulent or improper activity, including, without limitation, granting Kubbo access to any password-protected portions of your Account. If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
                    <p>&nbsp;</p>
                    <p><strong>4. ACCOUNTS</strong></p>
                    <p><strong>Username and Password</strong></p>
                    <p>During the Account creation process, you will be required to select a password (“<strong>Login Information</strong>”). You shall not share the Account or the Login Information, nor let anyone else access your Account or do anything else that might jeopardize the security of your Account. In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must immediately notify Kubbo and modify your Login Information. You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information, including purchases, whether or not authorized by you. You are responsible for anything that happens through your Account.</p>
                    <p>Kubbo reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates the third party’s rights.</p>
                    <p>&nbsp;</p>
                    <p><strong> 5. USE OF THE SERVICE</strong></p>
                    <p>You shall not use our Content off of our Service, except as we may allow use of Kubbo Licensed Elements for certain fan site use approved by us in writing. We do not approve and you may not create or use “retro” or substitute sites or services that offer game play with our Content off of our Service.</p>
                    <p>In addition, you must follow our rules, and the spirit of them, including <a title="The Kubbo Way" href="https://www.habbo.cm/playing-Kubbo/Kubbo-way" target="_blank" rel="noopener">The Kubbo Way</a> and without limitation:</p>
                    <ul>
                    <li>No false information: You shall not create an Account using a false identity or information, or on behalf of someone other than yourself. You shall not use the Service if you have previously been removed or banned from the Service by Kubbo. You shall not falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; or forge headers or otherwise manipulate identifiers to disguise the origin of any material transmitted through the Service.</li>
                    <li>No use of others’ content without proper permission: You shall not use any User-Generated Content or other content that belongs to other people and pass it off as your own; this includes any content that you might have found elsewhere on the Internet. If anyone contributes to your User-Generated Content or has any rights to your User-Generated Content, or if anyone appears in the User-Generated Content, then you must also have their permission to submit such User-Generated Content to Kubbo under these Terms.</li>
                    <li>No commercial or political use: You shall use your Account and the Service only for non-commercial and non-political purposes. You shall not use the Service or any features on the Service to advertise, or solicit, or transmit any commercial advertisements, including chain letters, junk or spam e-mail, surveys, contests, pyramid schemes, or repetitive messages to anyone; to distribute unsolicited e-mail to others for commercial use or otherwise; or harvest or otherwise collect personal information about other individuals, including e-mail addresses or screen names for the purpose of sending unsolicited e-mails or for exchange. You shall not advertise or offer to sell or buy any goods or services for any commercial or business purpose. You shall not use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory of visitors to the Service or other visitor or usage information or any portion thereof.</li>
                    <li>No harassment, defamation, or profanity: You shall not publish, post, upload, distribute or disseminate any material or information on the Service which: (i) is inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information (including but not limited to pornography, depiction of acts of violence and sexual acts, and material and information which may perpetuate hatred against any person or group or having the likely effect of causing harm or offence); (ii) is a false statement about a person or their property or business which is actuated by malice and may cause financial harm to that person; (iii) is misleading or deceptive; (iv) is a breach of confidence or an invasion of a person's right to privacy; (v) may cause us to breach, or be involved in a breach of law, a code or an instrument which governs our conduct; or (vi) otherwise infringes any person's rights, including without limitation their intellectual property rights, e.g., copyright or trademark rights (or rights of privacy or publicity).</li>
                    <li>No harm, damage or interference: You shall not upload files that contain, or otherwise intentionally release or disseminate, viruses, Trojan horses, worms, time bombs, cancel bots, corrupted files, or any other similar software or programs that may interfere with or damage Kubbo, the Service, its Content or the operation of another's Device or property of another. You shall not download any file posted by another visitor of a home page, chat room or message board that you know, or reasonably should know, cannot be legally distributed. You shall not restrict or inhibit any other user from using and enjoying the home pages, chat room and message boards; or violate any code of conduct or other guidelines which may be displayed on the Service for any particular feature or service.</li>
                    <li>No law violations or conspiracy: You shall not violate, or encourage others to violate, any laws or regulations or the legal rights of another individual, on the Service.</li>
                    <li>No refusal to cooperate: You shall not refuse to follow the instructions of any personnel of Kubbo or a Kubbo affiliate.</li>
                    </ul>
                    <p>Kubbo reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms, any applicable Additional Terms, or the Service itself. Kubbo reserves the right to take action as a result, which may include terminating your Account and prohibiting you from using the Service.</p>
                    <p>&nbsp;</p>
                    <p><strong>6.</strong> <strong>THIRD PARTIES</strong></p>
                    <p>The Service may also contain links, as part of third-party ads or content on the Service or otherwise, to or from third-party websites (“<strong>Linked Sites</strong>”), including websites operated by advertisers, licensors, licensees, and certain other third parties who may have business relationships with Kubbo, e.g., inside of the Kubbo Game Center, or via links to sites outside of the Service that are operated by third parties. Kubbomay have no control over the content, operations, policies, terms, or other elements of Linked Sites, and Kubbo does not assume any obligation to review any Linked Sites. Kubbo doesnot endorse, approve, or sponsor any Linked Sites, or any third-party content, advertising, information, materials, products, services, or other items. Furthermore, Kubbo is not responsible for the quality or delivery of the products or services offered, accessed, obtained by or advertised at such sites. Finally, Kubbo will under no circumstances be liable for any direct, indirect, incidental or special loss or other damage, whether arising from negligence, breach of contract, defamation, infringement of copyright or other intellectual property rights or otherwise, arising out of or related to Linked Sites or their products or services. Any activities you engage in connection with any of the same are subject to the privacy and other policies, terms and conditions of use and/or sale, and rules issued by the operator of the Linked Sites. The Linked Sites may send cookies or solicit personal information from you, and as we have no control over these Linked Sites you agree that we have no responsibility for their actions and you should review their privacy policies. Kubbo disclaims all liability in connection therewith.</p>
                    <p>Further, where part of the Service contains advertising, content and other material submitted to us by third parties, please note that those third parties are responsible for ensuring that material submitted for inclusion on the Service complies with all legal and regulatory requirements. Kubbo disclaims all liability in connection with such advertising or other third-party content or material.</p>
                    <p>Any interactions, correspondence, transactions, and other dealings that you have with any third parties found on or through the Service (including on or via Linked Sites or third-party advertisements or content made available on or through our Service) are solely between you and the third party (including issues related to the content of third-party advertisements, payments, delivery of goods, warranties (including product warranties), privacy and data security, and the like). Kubbo disclaims all liability in connection therewith.</p>
                    <p>&nbsp;</p>
                    <p><strong>7. UPDATES AND MODIFICATIONS TO THE SERVICE</strong></p>
                    <p>You understand that the Service is an evolving one. Kubbo may require that you accept updates to the Service and to the third-party software you have installed on your Device. You acknowledge and agree that Kubbo may update and modify the Service, with or without notifying you. You may need to update third-party software from time to time in order to continue to use the Service.</p>
                    <p>&nbsp;</p>
                    <p><strong>8. TERMINATION; SURVIVAL</strong></p>
                    <p>WITHOUT LIMITATION, WE MAY TERMINATE YOUR LICENSE TO THE SERVICE OR OTHERWISE SUSPEND OR TERMINATE YOUR ACCOUNT AT ANY TIME WITH OR WITHOUT NOTICE TO YOU IF: (A) YOU BREACH THESE TERMS OR ANY APPLICABLE ADDITIONAL TERMS INCLUDING WITHOUT LIMITATION <a href="https://www.habbo.cm/playing-Kubbo/Kubbo-way">THE Kubbo WAY</a>; (B) YOU HAVE NOT ACCESSED YOUR ACCOUNT FOR 180 DAYS; OR (C) IF THE SERVICE IS GOING TO BE SHUT DOWN OR MERGED WITH OTHER SITES AND/OR SERVICES. IN THESE SITUATIONS YOUR ACCOUNT LICENSE TO USE THE SERVICE OR A PART THEREOF WILL BE AUTOMATICALLY TERMINATED. IN SUCH EVENT, Kubbo SHALL NOT BE REQUIRED TO PROVIDE REFUNDS, BENEFITS OR OTHER COMPENSATION TO USERS IN CONNECTION WITH SUCH DISCONTINUED SERVICES. TERMINATION OF YOUR ACCOUNT CAN INCLUDE DISABLING YOUR ACCESS TO THE SERVICE OR ANY PART THEREOF INCLUDING ANY CONTENT YOU OR OTHERS SUBMITTED.</p>
                    <p>WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SERVICE, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR ANY APPLICABLE ADDITIONAL TERMS. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS.</p>
                    <p>You accept that you have six months from when your account gets banned to contact us about an appeal. After six months our Customer Service will no longer be able to assist you.</p>
                    <p>You may terminate your access to the Service at any time for any reason without penalty by contacting us <a href="https://habbo.cm/">here</a>, at which point you will no longer be permitted to use the Service. We will not refund you the fees (or any part of them) that we have already charged you.</p>
                    <p>Any suspension or termination will not affect your obligations to Kubbo under these Terms or any applicable Additional Terms. Upon suspension or termination of your access to the Service, or upon notice from Kubbo, all rights granted to you under these Terms or any applicable Additional Terms will cease immediately, and you agree that you will immediately discontinue use of the Service. The provisions of these Terms and any applicable Additional Terms, which by their nature should survive your suspension or termination will survive, including the rights and licenses you grant to Kubbo in these Terms, as well as the indemnities, releases, disclaimers, and limitations on liability and the provisions regarding jurisdiction, choice of law, no class action, and mandatory arbitration.</p>
                    <p>&nbsp;</p>
                    <p><strong>9. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY; SOLE AND EXCLUSIVE REMEDY; INDEMNIFICATION</strong></p>
                    <p><strong>9.1 DISCLAIMER OF WARRANTIES</strong></p>
                    <p>YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR OWN RISK.</p>
                    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. Kubbo DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS AS TO: THE SERVICE AND ITS FUNCTIONS, FEATURES, AND ELEMENTS; ANY PRODUCTS OR SERVICES OFFERED OR REFERENCED AT OR LINKED THROUGH THE SERVICE; THE SECURITY ASSOCIATED WITH THE TRANSMISSION OF YOUR USER-GENERATED CONTENT TO OR VIA THE SERVICE; THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                    <p>&nbsp;</p>
                    <p><strong>9.2 LIMITATION OF LIABILITY; SOLE AND EXCLUSIVE REMEDY</strong></p>
                    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Kubbo SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO YOUR ACCESS TO AND USE OF THE SERVICE AND YOUR RIGHTS UNDER THESE TERMS OF SERVICE OR ANY APPLICABLE ADDITIONAL TERMS, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Kubbo HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU FURTHER AGREE THAT UNDER NO CIRCUMSTANCES SHALL Kubbo SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO Kubbo IN ACCORDANCE WITH THESE TERMS OF SERVICE OR ANY APPLICABLE ADDITIONAL TERMS IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO Kubbo DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND Kubbo’S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH Kubbo IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT. IF SUCH REMEDIES ARE DEEMED INSUFFICIENT UNDER ANY APPLICABLE LAW, Kubbo’S LIABILITY SHOULD INSTEAD BE LIMITED TO THE LESSER OF 500 EUROS OR THE MINIMUM AMOUNT REQUIRED BY LAW.</p>
                    <p>&nbsp;</p>
                    <p><strong>9.3</strong> <strong>JURISDICTIONAL LIMITATIONS</strong></p>
                    <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above disclaimers and limitations may not apply to you. To the extent that Kubbo may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope of such warranty and the extent of Kubbo’s liability shall be the minimum permitted under such applicable law. TO THE EXTENT REQUIRED BY APPLICABLE LAW, NOTHING IN THESE TERMS OF SERVICE OR ANY APPLICABLE ADDITIONAL TERMS SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF Kubbo.</p>
                    <p>&nbsp;</p>
                    <p><strong>9.4 INDEMNITY</strong></p>
                    <p>You agree to indemnify, defend and hold Kubbo and its successors and assigns harmless from any claim, demand, damages or other losses, including reasonable attorneys’ fees, asserted by any third party resulting from or arising out of: our use of information you submit to us (including your User-Generated Content) through the Service; your use of the Service and your activities in connection with the Service; any breach by you of these Terms or any applicable Additional Terms; your violation of any laws in connection with your use of the Service or your activities in connection with the Service; content transmitted through your Device that infringes, violates, or misappropriates any copyright, trademark, trade secret, trade dress, patent, publicity, privacy, or other right of any person or entity; and any misrepresentation made by you (all of the foregoing, “<strong>Claims and Losses</strong>”). You will cooperate as fully required by us in the defense of any Claim and Loss. Notwithstanding anything to the contrary, we retain the exclusive right to control, settle, compromise, and pay any and all Claims and Losses. We reserve the right to assume the exclusive defense and control of any Claims and Losses. You will not settle any Claims and Losses without, in each instance, the prior written consent of an officer of Kubbo.</p>
                    <p>&nbsp;</p>
                    <p>s</p>
                    <p><strong>10. ARBITRATION AND CLASS ACTION WAIVER</strong></p>
                    <p>Certain portions of this Section 10 are deemed to be a “written agreement to arbitrate” pursuant to the U.S. Federal Arbitration Act and apply to all United States users of the Service and to all other users to the extent the mandatory laws in their country of residence permit the various terms of this Section (so some provisions will not apply to some users if doing so would violate mandatory applicable law). You and Kubbo agree that we intend that this Section 10 satisfies the “writing” requirement of the Federal Arbitration Act. This Section 10 can only be changed or terminated upon mutual agreement.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1 Disputes</strong>. Any controversy, allegation, or claim between you and Kubbo arising out of or relating to the Service, the Content, these Terms, or any applicable Additional Terms, whether heretofore or hereafter arising are <strong>“Disputes”</strong>.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.1 First – Try to Resolve</strong>. If you or Kubbo becomes aware of a Dispute, then you and we agree to send a written notice to the other providing a reasonable description of the Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact information that you provide us. But if no such information exists or if such information is not current, then we have no obligation under this Section 10.1.1. Your notice to us must be sent to: Kubbo, Legal, Kaarlenkatu 11, 00530 Helsinki, Finland. For a period of sixty (60) days from the date of receipt of notice from the other party, Kubboand you will engage in a dialogue in order to attempt to resolve the Dispute, though nothing will require either you or Kubbo to resolve the Dispute on terms with respect to which you and Kubbo, in each of our sole discretion, are not comfortable.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.2</strong> <strong>Arbitration of Most Disputes</strong>. If we cannot resolve a Dispute as set forth in Section 10.1.1 within sixty (60) days of receipt of the notice, then either you or we may submit the Dispute to formal arbitration in accordance with this Section 10.1, unless it is an Excluded Dispute, which shall be handled in accordance with Section 10.2.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.3 Arbitrator</strong>. Upon expiration of the applicable sixty (60)-day period and to the fullest extent permitted by applicable law, a Dispute will be resolved solely by binding arbitration in accordance with the then-current JAMS Comprehensive Arbitration Rules &amp; Procedures of JAMS, Inc. (“<strong>JAMS</strong>”). JAMS procedures, rules, and fee information are available, as follows:</p>
                    <p>JAMS: 949.224.1810</p>
                    <p><a href="https://www.jamsadr.com/">https://www.jamsadr.com/</a></p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.4 Nature, Limitations, and Location of Alternative Dispute Resolution</strong>. In arbitration, as with a court, the arbitrator must honor the terms of these Terms (and any applicable Additional Terms) and can award the prevailing party damages and other relief (including attorneys’ fees). However, WITH ARBITRATION: (A) THERE IS NO JUDGE OR JURY, (B) THE ARBITRATION PROCEEDINGS AND ARBITRATION OUTCOME ARE SUBJECT TO CERTAIN CONFIDENTIALITY RULES, AND (C) JUDICIAL REVIEW OF THE ARBITRATION OUTCOME IS LIMITED. All parties to the arbitration will have the right, at their own expense, to be represented by an attorney or other advocate of their choosing. If an in-person arbitration hearing is required, then it will be conducted in the metropolitan area where you are a resident at the time the Dispute is submitted to arbitration. You and we will pay the administrative and arbitrator’s fees and other costs in accordance with the applicable arbitration rules; but, if applicable arbitration rules or laws require Kubbo to pay a greater portion or all of such fees and costs in order for this Section 10 to be enforceable, then Kubbo will have the right to elect to pay the fees and costs and proceed to arbitration. Discovery will be permitted pursuant to the applicable arbitration rules. The arbitrator’s decision must consist of a written statement stating the disposition of each claim of the Dispute, and must provide a statement of the essential findings and conclusions on which the decision and any award (if any) is based. Judgment on the arbitration decision and award (if any) may be entered in or by any court that has jurisdiction over the parties pursuant to Section 9 of the Federal Arbitration Act.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.5 No Class Action Matters</strong>. Disputes will be arbitrated only on an individual basis and will not be consolidated with any other arbitrations or other proceedings that involve any claim or controversy of any other party. But if, for any reason, any court with competent jurisdiction or any arbitrator selected pursuant to Section 10.1.3 holds that this restriction is unconscionable or unenforceable, then our agreement in Section 10.1.2 to arbitrate will not apply, and the Dispute must be brought exclusively in court pursuant to Section 10.3.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.1.6 Limited Time to File Claims</strong>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR WE WANT TO ASSERT A DISPUTE (BUT NOT A EXCLUDED DISPUTE) AGAINST THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY DELIVERY OF WRITTEN NOTICE AS SET FORTH IN SECTION 10.1.1 WITHIN ONE (1) YEAR AFTER DISCOVERY OF THE DISPUTE ARISES -- OR IT WILL BE FOREVER BARRED.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.2 Excluded Disputes</strong>. The following disputes constitute <strong>“Excluded Disputes”</strong> hereunder: (i) any Dispute regarding any of Kubbo’s actual or alleged intellectual property rights, (ii) Disputes, to the extent that the laws of the user’s local jurisdiction authorize may be brought in small claims court, and (iii) legal action taken by Kubbo to seek an injunction or other equitable relief in connection with, any loss, cost, or damage (or any potential loss, cost, or damage) relating to the Service, any Content, your User-Generated Content and/or Kubbo’s intellectual property rights (including such Kubbo may claim that may be in dispute), Kubbo’s operations, and/or Kubbo’s products or services (collectively, <strong>“Injunctive Relief”</strong>).</p>
                    <p>&nbsp;</p>
                    <p><strong>10.2.1 First – Try to Resolve</strong>. If (x) any Excluded Dispute arises, and (y) excluding Excluded Disputes involving Injunctive Relief, you and we agree to send a written notice to the other providing a reasonable description of the Excluded Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact information that you provide us. But if no such information exists or if such information is not current, then we have no obligation under this Section 10.2.1. Your notice to us must be sent to: Kubbo, Legal, Kaarlenkatu 11, 00530 Helsinki, Finland. For a period of sixty (60) days from the date of receipt of notice from the other party, Kubbo and you will engage in a dialogue in order to attempt to resolve the Excluded Dispute, though nothing will require either you or Kubbo to resolve the Excluded Dispute on terms with respect to which you and Kubbo, in each of our sole discretion, are not comfortable.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.2.2 </strong>If we cannot resolve authorized Excluded Dispute as set forth in Section 10.2.1 within sixty (60) days of receipt of the notice, then either you or we may submit the Excluded Dispute to formal arbitration pursuant to Section 10.1 only if you and Kubbo consent, in a writing signed by you and an officer or business and legal affairs representative of Kubbo, to have that Excluded Dispute subject to arbitration. In such a case (and only in such a case), that Excluded Dispute will be deemed a “Dispute” and shall be governed by Section 10.1. In the absence of such agreement, Section 10.1 shall not apply to Excluded Disputes, and Sections 10.3 and 15.1 shall apply.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.2.3 Injunctive Relief</strong>. With respect to Excluded Disputes involving Injunctive Relief, Sections 10.3 and 15.1 apply, and Kubbo may institute such Excluded Disputes as authorized therein immediately.</p>
                    <p>&nbsp;</p>
                    <p><strong>10.3 Jurisdiction</strong>. Except to the extent that arbitration is required in Section 10.1 or authorized in Section 10.2, and except as to the enforcement of any arbitration decision or award, any action or proceeding relating to any Dispute or Excluded Dispute may only be instituted in Helsinki, Finland. Accordingly, you and Kubbo consent to the exclusive personal jurisdiction and venue of such courts for such matters.</p>
                    <p>&nbsp;</p>
                    <p><strong>11. OTHER DISPUTE RESOLUTION WHERE ARBITRATION IS NOT APPLICABLE</strong></p>
                    <p>This Section 11 applies to all users of the Service, if, and only to the extent that, the mandatory applicable laws do not permit arbitration of Disputes in accordance with Section 10.</p>
                    <p>&nbsp;</p>
                    <p><strong>11.1 Section 11 Disputes</strong>. If any controversy, allegation, or claim (including any non-contractual claim) arises out of or relates to the Service, the Content, these Terms, or any applicable Additional Terms, whether heretofore or hereafter arising or to any of Kubbo’s actual or alleged intellectual property rights (collectively, a “<strong>Section 11 Dispute</strong>”), then you and we agree to send a written notice to the other providing a reasonable description of the Section 11 Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact information that you provide us. But if no such information exists or if such information is not current, then we have no obligation under this Section 11.1. Your notice to us must be sent to: Kubbo, Legal, Kaarlenkatu 11, 00530 Helsinki, Finland. Except to the extent Kubbo determines it reasonably necessary to seek immediate relief to protect its or third-party rights, for a period of sixty (60) days from the date of receipt of notice from the other party, Kubbo and you will engage in a dialogue in order to attempt to resolve the Section 11 Dispute, though nothing will require either you or Kubbo to resolve the Section 11 Dispute on terms with respect to which you and Kubbo, in each of our sole discretion, are not comfortable.</p>
                    <p>&nbsp;</p>
                    <p><strong>11.2</strong> <strong>Jurisdiction</strong>. The parties agree that the courts in Finland shall have jurisdiction of any Section 11 Dispute and you consent to the venue there.</p>
                    <p>&nbsp;</p>
                    <p><strong>11.3</strong> <strong>Limited Time to File Claims</strong>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR WE WANT TO ASSERT A SECTION 11 DISPUTE AGAINST THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY DELIVERY OF WRITTEN NOTICE AS SET FORTH IN SECTION 11.1) WITHIN ONE (1) YEAR AFTER DISCOVERY OF THE SECTION 11 DISPUTE ARISES -- OR IT WILL BE FOREVER BARRED.</p>
                    <p>&nbsp;</p>
                    <p><strong>12. SEVERABILITY</strong></p>
                    <p>You and Kubbo agree that if any portion of these Terms or any applicable Additional Terms, or of the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>, is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which shall continue to be in full force and effect.</p>
                    <p>&nbsp;</p>
                    <p><strong>13. WIRELESS</strong></p>
                    <p>The Service may offer certain features and services that are available to you via your wireless Device. These features and services may include the ability to access the Service’s features and upload content to the Service, receive messages from the Service, and download applications to your wireless Device (collectively, “<strong>Wireless Features</strong>”). Standard messaging, data and other fees may be charged by your carrier to participate in Wireless Features. Fees and charges may appear on your wireless bill or be deducted from your pre-paid balance. Your carrier may prohibit or restrict certain Wireless Features and certain Wireless Features may be incompatible with your carrier or wireless Device. You should check with your carrier to find out what plans are available and how much they cost.</p>
                    <p>You agree that as to the Wireless Features for which you are registered for, we may send communications to your wireless Device regarding us or other parties. Further, we may collect information related to your use of the Wireless Features. If you have registered via the Service for Wireless Features, then you agree to notify Kubbo of any changes to your wireless contact information (including phone number) and update your Account on the Service to reflect the changes.</p>
                    <p>&nbsp;</p>
                    <p><strong>14. LINKING TO OUR SERVICE</strong></p>
                    <p>We grant you a limited, non-exclusive, revocable, non-assignable, personal and non-transferable license to create hyperlinks to the Service, so long as: (a) the links only incorporate text, and do not use any Trademarks, (b) the links and the content on your web site do not suggest any affiliation with Kubbo or cause any other confusion, and (c) the links and the content on your web site do not portray Kubbo or its products or services in a false, misleading, derogatory, or otherwise offensive manner, and do not contain content that is unlawful, offensive, obscene, lewd, lascivious, filthy, violent, threatening, harassing, or abusive, or that violate any right of any third party or are otherwise objectionable to Kubbo. Kubbo reserves the right to suspend or prohibit linking to the Service for any reason, in its sole discretion, without advance notice or any liability of any kind to you or any third party.</p>
                    <p>&nbsp;</p>
                    <p><strong>15. GENERAL PROVISIONS</strong></p>
                    <p><strong>15.1 Governing Law</strong>. You and we disclaim any application to these Terms of the Convention on Contracts for the International Sale of Goods. To the maximum extent permitted by the mandatory laws in your country of residence, these Terms, any applicable Additional Terms and any dispute arising out of or in connection with it or its subject matter or formation (including non-contractual disputes), will be governed by, and construed in accordance with, the laws of the United States for users located in the United States, and the laws of Finland for all other users, without regard to conflicts of law provisions that might apply other law.</p>
                    <p>&nbsp;</p>
                    <p><strong>15.2 Assignment</strong>. Kubbo may assign or delegate these Terms, any applicable Additional Terms and/or the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms and/or the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a> without Kubbo’s prior written consent, and any unauthorized assignment and delegation by you is ineffective.</p>
                    <p>&nbsp;</p>
                    <p><strong>15.3</strong> <strong>Entire Agreement</strong>. These Terms, any applicable Additional Terms, and any documents expressly incorporated by reference herein (including the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>), contain the entire understanding of you and Kubbo, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Service.</p>
                    <p>&nbsp;</p>
                    <p><strong>15.4 No Waiver</strong>. The failure of Kubbo to require or enforce strict performance by you of any provision of these Terms, any applicable Additional Terms and/or the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a>or failure to exercise any right under them shall not be construed as a waiver or relinquishment of Kubbo’s right to assert or rely upon any such provision or right in that or any other instance. The express waiver by Kubbo of any provision, condition, or requirement of these Terms, any applicable Additional Terms and/or the Service’s <a href="../../help/2-poltica-de-privacidad">Privacy Policy</a> shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement. Except as expressly and specifically set forth in this these Terms and any applicable Additional Terms, no representations, statements, consents, waivers, or other acts or omissions by Kubbo shall be deemed a modification of these Terms or any applicable Additional Terms nor legally binding, unless documented in physical writing, hand signed by you and a duly appointed officer of Kubbo.</p>
                    <p>&nbsp;</p>
                    <p><strong>15.5 Notices; Updates To Terms; Questions and Customer Support</strong>. As our Service evolves, the terms and conditions under which we offer the Service may prospectively be modified and we may cease offering the Service under the Terms or any applicable Additional Terms for which they were previously offered. Accordingly, each time you sign in to or otherwise use the Service you are entering into a new agreement with us on the then-applicable terms and conditions, and you agree that we may notify you of other terms by posting them on the Service (or in any other reasonable manner of notice which we elect), and that your use of the Service after such notice constitutes your going forward agreement to the other terms for your new use and transactions. You can reject any new, revised or additional terms by discontinuing use of the Service and canceling your Account.</p>
                    <p>You agree that we may notify you via postings on the Service, and via e-mail or any other reasonable communications means to contact you based on the information you provided to us when creating your Account, with respect to changes to these Terms or any applicable Additional Terms. You agree to promptly notify us if you change your e-mail or mailing address by updating your Account settings.</p>
                    <p>All notices given by you or required from you under these Terms, any applicable Additional Terms and/or the Service’s<a href="../../help/2-poltica-de-privacidad"> Privacy Policy </a>shall be in writing and addressed to: Kubbo, Legal, Kaarlenkatu 11, 00530 Helsinki, Finland. Any notices that you provide without compliance with this Section on Notices shall have no legal effect.</p>
                    <p>If you have a question about the Service, you may contact our <a href="https://help.habbo.cm/anonymous_requests/new">Customer Service</a>. You acknowledge that the provision of customer support is at Kubbo’s sole discretion and that we have no obligation to provide you with customer support of any kind.</p>
                    <p>&nbsp;</p>
                    <p><strong>15.6 Equitable Remedies</strong>. You acknowledge that the rights granted and obligations made under these Terms and/or any applicable Additional Terms to Kubbo are of a unique and irreplaceable nature, the loss of which shall irreparably harm Kubbo and which cannot be replaced by monetary damages alone so that Kubbo shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you. You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any Content or other product or service, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by <a href="https://help.habbo.cm/entries/23027093-terms-and-conditions#section9">Section 9</a> (if any).</p>
                    <p>&nbsp;</p>
                    <p><strong>15.7</strong> <strong>Copyright Infringement</strong>. If you believe that your copyright or other rights have been infringed by Content on the Service, please contact our <a href="https://help.habbo.cm/anonymous_requests/new">Customer Service</a>. For rights holders in the U.S., please click <a href="https://help.habbo.cm/entries/278050-infringements-policy">here </a> to see our infringement notice and takedown policy.</p>
                    <p><strong>15.8 Force Majeure</strong>. Kubbo shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of Kubbo, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond Kubbo’s control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>16. TERMS APPLICABLE FOR APPLE iOS</strong></p>
                    <p>If you are accessing or using the Service through an Apple Device, the following additional terms and conditions are applicable to you and are incorporated into the Terms by this reference:</p>
                    <p>(i) To the extent that you are accessing the Service through an Apple Device, you acknowledge that these Terms are entered into between you and Kubbo, and that Apple, Inc. (“<strong>Apple</strong>”) is not a party to these Terms other than as third-party beneficiary as contemplated below.</p>
                    <p>(ii) The license granted to you in <a href="https://help.habbo.cm/entries/23027093-Terms-of-Service"><strong>Section 1</strong></a> of these Terms is subject to the permitted Usage Rules set forth in the App Store Terms of Service (see: <a href="https://www.apple.com/legal/itunes/us/terms.html">https://www.apple.com/legal/itunes/us/terms.html</a>) and any third party terms of agreement applicable to the Service.</p>
                    <p>(iii) You acknowledge that Kubbo, and not Apple, is responsible for providing the Service and Content thereof.</p>
                    <p>(iv) You acknowledge that Apple has no obligation whatsoever to furnish any maintenance any support services to you with respect to the Service.</p>
                    <p>(v) In the event of any failure of the Service to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price you; and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Service.</p>
                    <p>(vi) Notwithstanding anything to the contrary herein, and subject to the terms in these Terms, you acknowledge that, solely as between Apple and Kubbo, Kubbo, and not Apple is responsible for addressing any claims you may have relating to the Service, or your possession and/or use thereof, including, but not limited, to: (i) product liability claims, (ii) any claim that the Service fails to confirm to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</p>
                    <p>(vii) Further, you agree that if the Service, or your possession and use of the Service, infringes on a third party's intellectual property rights, you will not hold Apple responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claims.</p>
                    <p>(viii) You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted</p>
                    <p>(ix) You acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of these Terms, and that, upon your acceptance of the terms and conditions of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary thereof.</p>
                    <p>(x) When using the Service, you agree to comply with any and all third party terms that are applicable to any platform, website, technology or service that interacts with the Service.</p>
                    <p>&nbsp;</p>
                    <p><strong>Location-Based Features</strong>.</p>
                    <p>If you have enabled GPS, geo-location or other location-based features on any mobile app(s) or feature(s), you acknowledge that your Device location may be tracked and may be shared with others consistent with the Service’s <a href="../../help/2-poltica-de-privacidad"><strong>Privacy Policy</strong></a>. Some mobile app(s) or feature(s) allow for you to disable location-based features or manage preferences related to them. However, you can terminate Device location tracking by us by disinstalling any mobile app(s) or feature(s). The location-based services offered in connection with our mobile app(s) or feature(s) are for individual use only and should not be used or relied on as an emergency locator system, used while driving or operating vehicles, or used in connection with any hazardous environments requiring fail-safe performance, or any other situation in which the failure or inaccuracy of use of the location-based services could lead directly to death, personal injury, or severe physical or property damage. The location-based services are not suited or intended for family finding purposes, fleet tracking, or any other type of business or enterprise use – other products exist today that may be used specifically for these purposes.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    © 2021 habbo.cm All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsView
