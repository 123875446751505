import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getBadgeUrl, getProfileUrl, LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { StaffComposer } from '../../packets/StaffComposer';

const TeamView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const navigate = useNavigate();
  const params = useParams();

  const [staffs, setStaffs] = useState<any[]>([]);
  const [ranks, setRanks] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("all");

  const selectSection = (section) => {
    if (section === null) setSection("all");
    else setSection(section);
  }

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    if (params === null || params.type === null) navigate("/me", { replace: true });
    selectSection(params.type);
    sendMessage(JSON.stringify(new StaffComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "staff" && message.data !== null) {
        setStaffs(JSON.parse(message.data.staffs));
        setRanks(JSON.parse(message.data.ranks));
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    document.title = "Staff | Habbo"
    if (ranks !== null) {
      if (!(section === "all" || ranks.filter(obj => obj.name === section).length === 1)) navigate("/me", { replace: true });
    }
    if (staffs !== null && ranks !== null) setLoading(false)
  }, [staffs, ranks])

  const changeSection = (section) => {
    setLoading(true);
    navigate("/team/" + section, { replace: true });
    setSection(section);
    setLoading(false);
  }

  const getImagerLook = (look) => "https://nitro-imager.habbo.cm/?figure=" + look + "&direction=2&head_direction=3&gesture=sml&size=l&img_format=gif";

  return (
    <>
      {loading ? <LoadingView /> : (
        <>
          <div className="max-w-5xl grid grid-cols-12 w-full gap-4 mx-auto mt-5 animate__animated animate__fadeIn animate__slower">

            {''}
            <div className="col-span-12 flex flex-col gap-4">
              <div className="flex items-center bg-tertiary rounded-lg">
                <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                  <div className="cmCommentIcon"></div>
                  <span>Announcement</span>
                </div>
                <div className="p-3.5 text-sm text-white text-opacity-70">
                  Join Discord to get updates.
                  <a href="https://discord.gg/habbocm" target='__blank' className='ml-1'>
                    discord.gg/habbocm
                  </a>
                </div>
              </div>
              <p className='text-xl font-bold text-white text-opacity-90 px-4'>Team of Staff</p>
              {section === "all" ? (
                <>
                  {ranks.filter(obj => obj.name !== "Devolper" && obj.name !== "GameX" && obj.name !== "Pixler" && obj.name !== "Builder").map((rank) =>
                    <div key={Math.random()} className="flex flex-col gap-4">
                      <div className="rankHeader bg-primary p-3 rounded-lg border-l-[8px] border-[#fe9800] flex items-center gap-2 text-[#fe9800]">
                        <img src={getBadgeUrl(rank.badge)} />
                        <p className='poppins-bold text-lg'>{rank.name}</p>
                      </div>
                      <div className="grid grid-cols-12 gap-4">
                        {staffs.filter(obj => obj.rankId === rank.id && obj.name !== "GameX" && obj.name !== "Pixler" && obj.name !== "Botschafter" && obj.name !== "Xler").reverse().map((staff) =>
                          <Link to={`/profile/${staff.account.username}`} className="col-span-12 md:col-span-4 max-h-24 h-24 flex bg-tertiary rounded-lg border-r-[4px] border-r-primary" key={staff.account.id}>
                            <div className="w-[128px] overflow-hidden h-full bg-primary rounded-tl-lg rounded-bl-lg">
                              <img className='relative -top-8' src={`https://nitro-imager.habbo.cm/?figure=${staff.account.look}&size=l&direction=2&head_direction=3&gesture=sml`} alt="" />
                            </div>
                            <div className="flex flex-col p-3 truncate">
                              <p className='text-white text-lg'>{staff.account.username}</p>
                              <p>{staff.account.motto === null || staff.account.motto === "" ? (
                                <div className="text-xs italic text-quarternary">{LocalizeText(props.texts, 'staffs.no.motto')}</div>
                              ) : (
                                <p className='text-xs text-white text-opacity-70 truncate'>{staff.account.motto}</p>
                              )}</p>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {ranks.filter(obj => obj.name === section).map((rank) =>
                    <div className="mt-5 flex flex-col gap-2" key={Math.random()}>
                      <div className="rankHeader bg-primary p-3 rounded-lg border-l-[8px] border-[#fe9800] flex items-center gap-2 text-[#fe9800]">
                        <img src={getBadgeUrl(rank.badge)} />
                        <p className='poppins-bold text-lg'>{rank.name}</p>
                      </div>
                      <div className="grid grid-cols-12 gap-4">
                        {staffs.filter(obj => obj.rankId === rank.id).reverse().map((staff) =>
                            <div className="col-span-12 md:col-span-4 max-h-24 h-24 flex bg-tertiary rounded-lg border-r-[4px] border-r-primary" key={staff.account.id}>
                            <div className="w-[128px] overflow-hidden h-full bg-primary rounded-tl-lg rounded-bl-lg">
                              <img className='relative -top-8' src={`https://nitro-imager.habbo.cm/?figure=${staff.account.look}&size=l&direction=2&head_direction=3&gesture=sml`} alt="" />
                            </div>
                            <div className="flex flex-col p-3">
                              <p className='text-white text-lg'>{staff.account.username}</p>
                              <p>{staff.account.motto === null || staff.account.motto === "" ? (
                                <div className="text-xs italic text-quarternary">{LocalizeText(props.texts, 'staffs.no.motto')}</div>
                              ) : (
                                <p className='text-xs text-white text-opacity-70'>Danke für deine Unterstützung!</p>
                              )}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TeamView
