import React, { useState, useEffect, useRef } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Link, useNavigate } from 'react-router-dom';
import { WEBSOCKET_ADDRESS } from '../../Globals';
import { NotificationManager } from 'react-notifications';

interface BadgeInterface {
    account: any;
}

const BadgeView = (props: BadgeInterface) => {
    const [loading, setLoading] = useState(true);
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState(WEBSOCKET_ADDRESS);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [news, setNews] = useState<any[]>([]);

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageContent, setImageContent] = useState<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [badgeName, setBadgeName] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (props.account === null) navigate("/", { replace: true });
    }, [props.account])

    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;

        if (fileList && fileList.length > 0) {
            const file = fileList[0];

            if (file && /\.(gif)$/i.test(file.name)) {
                const selectedImage = await convertToBase64(file);
                const selectedImageUrl = URL.createObjectURL(fileList[0]);

                setImageSrc(selectedImageUrl);
                setImageContent(selectedImage);
            } else {
                NotificationManager.error("La imagen debe estar en GIF.", "Error")
            }
        }
    };

    const convertToBase64 = (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result) {
                    const base64String = reader.result.toString();
                    resolve(base64String);
                } else {
                    reject(new Error('Failed to convert the file to base64.'));
                }
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };

    const uploadBadge = () => {
        if(badgeName === "" || badgeDescription === ""){
            NotificationManager.error("Debes de escribir un nombre y descripción para la placa.", "Error")
            return;
        }
        
        fetch("https://int.habbo.cm/badge", {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({ userId: props.account.id, badge: imageContent.replace("data:image/gif;base64,", ""), name: badgeName, description: badgeDescription })
        }).then((response) => response.json())
            .then((resp) => {
                console.log("xdddd");
                if (resp.header === "ok"){
                    NotificationManager.success("Placa subida con éxito.", "OK")
                    setImageSrc(null);
                }
                else NotificationManager.error("Ha ocurrido un error, contacta con el staff.", "Error")
            })
    }

    return (
        <>
            <div className='container animate__animated animate__fadeIn'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='home-section-title mt-4 mb-2'>Sube tu placa</div>
                        <div className='card card-met'>
                            <div className='card-body metroclub-fs'>
                                <div className='alert bg-primary text-white text-center'>
                                    Al subir una placa entrará primero en aprobación por parte del equipo staff, si es aprobada se te colocará directamente en tu inventario.
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                {imageSrc === null && <button onClick={openFileInput} className="btn btn-dark btn-sm mb-1 w-100">Subir placa</button>}
                                {imageSrc !== null &&
                                    <>
                                        <div className='text-center'>
                                            <img src={imageSrc} className='img-fluid mb-3' />
                                        </div>
                                        <b>Nombre de la placa:</b>
                                        <input className='form-control mb-2' type="text" onChange={(e) => setBadgeName(e.target.value)} />
                                        <b>Descripción de la placa:</b>
                                        <input className='form-control mb-3' type="text" onChange={(e) => setBadgeDescription(e.target.value)} />
                                        <button onClick={() => { setImageSrc(null); setImageContent(null); }} className="btn btn-danger w-100 btn-sm mt-1">Borrar placa</button><br />
                                        <button onClick={uploadBadge} className="btn btn-success w-100 mt-1 mb-3">Subir placa</button><br />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='home-section-title mt-4 mb-2'>¿Para que sirven las placas?</div>
                        <div className='card card-met'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <img src="https://1.bp.blogspot.com/-tBN5XB0hf04/X6d4ol_BKwI/AAAAAAABfBc/Dy2mbL38khUlPe2NIOLc50pgD9AatkXoQCPcBGAsYHg/s0/489__-36n.png" />
                                    </div>
                                    <div className='col-md-10'>
                                        <div className='staff-whats-staff'>
                                            El equipo staff es un equipo de futbol de Marruecos el cual ganó la primera division de Inshallah y se encuentra situado como uno de los mejores equipos del mundo.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BadgeView;
