import { FC, useState } from "react";
import ModalCatalogItemView from "./ModalCatalogItemView";

interface catalogItemProps {
    item: any;
    canEdit: boolean;
}

const CatalogItemView: FC<catalogItemProps> = props => {
    const { item = [], canEdit = false } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    function rBackdrop() {
        document.querySelector('.modal-backdrop')?.remove();
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className='col-span-12 md:col-span-3 w-full bg-tertiary p-2 flex flex-col rounded-lg relative'>
                <div className="p-2 rounded-xl text-center bg-primary truncate text-white font-light">
                    {item.name}
                </div>
                <div className='cmPreislisteBackground h-40 mt-2 relative'>
                    <img className='cursor-pointer' onClick={openModal} src={item.image} alt={item.name} />
                    <div className="absolute bottom-1 flex items-center gap-3 px-4 w-full">
                    <div className="w-full rounded-lg flex items-center justify-center gap-2 text-sm bg-[#ffc000] p-1 px-2">
                        {item.price_credits == 1 ? "Unbekannt" : item.price_credits}
                        <img src="https://images.habbo.one/images/wallet/-1.png" alt="credits" />
                    </div>
                    <div className="w-full rounded-lg flex items-center justify-center gap-2 text-sm bg-[#4aa78f] p-1 px-2">
                        {item.price_diamonds == 1 ? "Unbekannt" : item.price_diamonds}
                        <img src="https://images.habbo.one/images/wallet/5.png" alt="diamonds" />
                    </div>
                </div>
                </div>
            </div>
            {isModalOpen && (
                <ModalCatalogItemView 
                    item={item} 
                    canEdit={canEdit} 
                    onClose={closeModal} 
                />
            )}
        </>
    );
}

export default CatalogItemView;
