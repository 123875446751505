class Data{
    tweetText: string;
    constructor(tweetText: string){
        this.tweetText = tweetText;
    }
}

export class CreateTweetComposer{
    header: string;
    data: Data;
    
    constructor(tweetText: string){
        this.header = "createTweet";
        this.data = new Data(tweetText);
    }
}