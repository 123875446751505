import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Link } from 'react-router-dom';

import LoadingView from '../loading/LoadingView';

import { NewsComposer } from '../../packets/NewsComposer';
import { getArticleRoute, timeSince, LocalizeText } from '../../Globals';


const ArticlesView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [news, setNews] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;
    document.title = LocalizeText(props.texts, 'article.title') + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (news !== null) setLoading(false)
  }, [news])

  return (
    <>
      {loading ? <LoadingView /> : (
        <>
          <div className='max-w-5xl gap-4 w-full mx-auto grid grid-cols-12 animate__animated animate__fadeIn animate__slower mt-5'>
            <div className="col-span-12 grid grid-cols-12 gap-4">
              {news !== null && news.length !== 0 &&
                <>
                  {news.map((article) =>
                    <Link key={article.id} to={getArticleRoute(article.id, article.title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                      <div className="flex items-center justify-between">
                        <p className='text-white text-opacity-80'>{article.title}</p>
                      </div>
                      <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${article.header})` }}></div>
                      <p className='text-white text-opacity-60 text-xs'>{article.shortStory}</p>
                      <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                        <p>Read All!</p>
                        <p>by {article.author.username}</p>
                      </div>
                      </Link>
                    )}
                    </>
                }
                </div>
            </div>
          </>
      )}
        </>
      )
      }

      export default ArticlesView