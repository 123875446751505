import React, { useState, useEffect, useMemo } from 'react';
import { HeaderView } from './components/header/HeaderView';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { PingComposer } from './packets/PingComposer';
import { TopsComposer } from './packets/TopsComposer';
import { CommunityComposer } from './packets/CommunityComposer';
import { NewsComposer } from './packets/NewsComposer';
import WelcomeView from './components/welcome/WelcomeView';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import FooterView from './components/footer/FooterView';
import TermsView from './components/help/TermsView';
import PrivacyView from './components/help/PrivacyView';
import LoadingView from './components/loading/LoadingView';
import LoginModalView from './components/auth/LoginModalView';
import RegisterNewView from './components/auth/RegisterNewView';
import ArticlesView from './components/articles/ArticlesView';
import ArticleView from './components/articles/ArticleView';
import TeamView from './components/team/TeamView';
import CommunityView from './components/community/CommunityView';
import { AuthComposer } from './packets/AuthComposer';
import DashboardView from './components/dashboard/DashboardView';
import ProfileView from './components/profile/ProfileView';
import SettingsView from './components/settings/SettingsView';
import PlayView from './components/play/PlayView';
import CatalogView from './components/catalog/CatalogView';
import VipsView from './components/vips/VipsView';
import EhrenamtPage from './components/team/EhrenamtPage';
import Regelwerk from './components/regeln/Regelwerk';
import BadgeView from './components/badges/BadgeView'; 

import 'react-notifications/lib/notifications.css';
import LoginView from './components/auth/LoginView';
import MaintenanceView from './components/maintenance/MaintenanceView';
import { InitComposer } from './packets/InitComposer';
import { TopHeaderView } from './components/header/TopHeaderView';

export const App = () => {
  const [socketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const [messageHistory, setMessageHistory] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [texts, setTexts] = useState({});
  const [tops, setTops] = useState({});
  const [community, setCommunity] = useState({});
  const [online, setOnline] = useState(0);
  const [account, setAccount] = useState<any>(null);
  const [discord, setDiscord] = useState<any>(null);

  const [maintenance, setMaintenance] = useState<any>(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      sendMessage(JSON.stringify(new InitComposer()));
    },
    onError: () => {
      setMaintenance(true)
    },
    shouldReconnect: (closeEvent) => true,
    share: true
  });

  useEffect(() => {
    if (lastMessage !== null) {
      var sessionStorage = window.sessionStorage;
      var localStorage = window.localStorage;

      var message = JSON.parse(lastMessage.data);

      if (message.header === "maintenance" && message.data !== null) {
        if (message.data.status === "yes") {
          setMaintenance(true);
          setLoaded(true);
        }
        else {
          setMaintenance(false);
          var localStorage = window.localStorage;

          if (localStorage.getItem("kubboch-sto") != null) {
            sendMessage(JSON.stringify(new AuthComposer(String(localStorage.getItem("kubboch-sto")))));
          }

          sendMessage(JSON.stringify(new PingComposer()));

          setInterval(() => {
            sendMessage(JSON.stringify(new PingComposer()));
          }, 5000);

          sendMessage(JSON.stringify(new TopsComposer()));
          sendMessage(JSON.stringify(new CommunityComposer()));
        }
      }

      if (message.header === "getAccount" && message.data !== null) {
        sessionStorage.setItem("kubboch-stoc", JSON.stringify(message.data));
        setAccount(message.data);
      }

      if (message.header === "authResponse" && message.data !== null) {
        if (message.data.data !== "Success") {
          sessionStorage.removeItem("kubboch-stoc");
          localStorage.removeItem("kubboch-sto");
        }
      }

      if (message.header === "usersTops" && message.data !== null) setTops(message.data);
      if (message.header === "topsStats" && message.data !== null) setCommunity(message.data);
      if (message.header === "pong" && message.data !== null) setOnline(message.data.online);
    }
  }, [lastMessage, setMessageHistory]);

  const disableMaintenance = () => {
    setLoaded(false);
    setMaintenance(false);

    var localStorage = window.localStorage;

    if (localStorage.getItem("kubboch-sto") != null) {
      sendMessage(JSON.stringify(new AuthComposer(String(localStorage.getItem("kubboch-sto")))));
    }

    sendMessage(JSON.stringify(new PingComposer()));

    setInterval(() => {
      sendMessage(JSON.stringify(new PingComposer()));
    }, 5000);

    sendMessage(JSON.stringify(new TopsComposer()));
    sendMessage(JSON.stringify(new CommunityComposer()));
  }

  const getLang = () => {
    fetch("/cms-texts.json", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setTexts(json);
      });
  };

  const getDiscord = () => {
    fetch("https://discord.com/api/guilds/1197706519928639498/widget.json", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setDiscord(json);
      });
  };

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;
    getLang();
    getDiscord();
  }, []);

  useEffect(() => {
    if (Object.keys(tops).length !== 0 && Object.keys(community).length !== 0 && Object.keys(texts).length !== 0) setLoaded(true);
  }, [tops, community, texts]);

  const logout = () => {
    window.localStorage.removeItem("kubboch-sto");
    window.sessionStorage.removeItem("kubboch-stoc");
    setAccount(null);
  };

  const HeaderCached = useMemo(() => <HeaderView texts={texts} online={online} tops={tops} community={community} account={account} logout={() => logout()} />, [account]);

  return (
    <>
      {
        maintenance ? <MaintenanceView disableMaintenance={disableMaintenance} /> :
          <div>
            {!loaded ? <LoadingView /> : (
              <Router>
                <NotificationContainer style={{ whiteSpace: "pre-line" }} />
                {account === null ? <></> : (
                    <TopHeaderView account={account} online={online} />
                )}
                <HeaderView texts={texts} online={online} tops={tops} community={community} account={account} logout={() => logout()} />
                <Routes>
                  <Route path="/" element={<WelcomeView account={account} texts={texts} />} />
                  <Route path="/maintenance" element={<MaintenanceView />} />
                  <Route path="/index" element={<WelcomeView account={account} texts={texts} />} />
                  <Route path="/login" element={<LoginView texts={texts} />} />
                  <Route path="/register" element={<RegisterNewView account={account} online={online}  texts={texts} />} />
                  <Route path="/articles" element={<ArticlesView texts={texts} />} />
                  <Route path="/article/:id/:name" element={<ArticleView texts={texts} account={account} />} />
                  <Route path="/terms" element={<TermsView />} />
                  <Route path="/privacy" element={<PrivacyView />} />
                  <Route path="/team/:type" element={<TeamView texts={texts} />} />
                  <Route path="/community/:type" element={<CommunityView texts={texts} />} />
                  <Route path="/me" element={<DashboardView account={account} texts={texts} discord={discord} />} />
                  <Route path="/profile/:name" element={<ProfileView account={account} texts={texts} />} />
                  <Route path="/settings" element={<SettingsView account={account} texts={texts} />} />
                  <Route path="/play" element={<PlayView account={account} texts={texts} />} />
                  <Route path="/catalog" element={<CatalogView account={account} />} />
                  <Route path="/vips" element={<VipsView />} />
                  <Route element={<Navigate to="/index" replace />} />
                  <Route path="/ehrenamt/:type" element={<EhrenamtPage texts={texts} />} />
                  <Route path="/regelwerk" element={<Regelwerk />} />
                  <Route path="/badges" element={<BadgeView account={account} />} /> 
                </Routes>
                <div style={{ marginTop: "50px" }}></div>
                {account === null ? <></> : (
                    <FooterView />
                )}
              </Router>
            )}
          </div>
      }
    </>
  );
};
