import React from 'react';

function Regeln() {
  return (
    <div className="max-w-5xl grid grid-cols-12 w-full gap-4 mx-auto mt-5 animate__animated animate__fadeIn animate__slower">
      <div className="col-span-12 flex items-center bg-tertiary rounded-lg">
        <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
          <div className="cmCommentIcon"></div>
          <span>Announcement</span>
        </div>
        <div className="p-3.5 text-sm text-white text-opacity-70">
          Join Discord to get updates.
          <a href="https://discord.gg/habbocm" target='__blank' className='ml-1'>
            discord.gg/habbocm
          </a>
        </div>
      </div>
      <div className="col-span-12 bg-tertiary rounded-lg flex flex-col px-4 py-3 text-white text-opacity-70 text-sm">
        <p>Regeln</p>
        <p className="lead">Bitte beachte die folgenden Regeln:</p>
        <br />
        <p><strong>§1 Verbot von Mitarbeitermanipulation und Verbreitung nachweislich falscher Informationen:</strong><br />
          Es ist streng verboten, einen Mitarbeiter zu belügen oder bewusst falsche Tatsachen darzulegen.</p>
        <br />
        <p><strong>§2 Untersagte Verhaltensweisen: Beleidigung, fortgesetzte Provokation und Hetze:</strong><br />
          Beleidigungen, wiederholte Provokationen oder Hetzerei gegenüber anderen Nutzern sind inakzeptabel und untersagt.</p>
        <br />
        <p><strong>§3 Passwortbetrug:</strong><br />
          Das Erschleichen von Accountdaten oder der unbefugte Zugriff auf fremde Accounts durch Passwortbetrug ist streng verboten.</p>
        <br />
        <p><strong>§4 Verbotene Äußerungen: Sexismus, Rassismus und Diskriminierung:</strong><br />
          Äußerungen, die sexuell, rassistisch oder diskriminierend sind und bestimmte Volksgruppen oder Religionen betreffen, sind in keiner Weise toleriert und strikt untersagt.</p>
        <br />
        <p><strong>§5 Verbot von unangemessenen Rauminhalten (genau so wie Raumname):</strong><br />
          Die Erstellung von Räumen mit unangemessenem Inhalt ist nicht gestattet und wird konsequent unterbunden.</p>
        <br />
        <p><strong>§6 Verbot von unpassenden Usernames:</strong><br />
          Die Verwendung eines unangemessenen Usernames ist nicht erlaubt und sollte vermieden werden.</p>
        <br />
        <p><strong>§7 Verbot von Identitätsbetrug oder irreführender Teamzugehörigkeit:</strong><br />
          Das Nachahmen einer falschen Identität oder das Vortäuschen, ein Staffmitglied zu sein, ist streng untersagt und wird entsprechend geahndet.</p>
        <br />
        <p><strong>§8 Fremdwerbung:</strong><br />
          Das Werben für andere Hotels oder das Teilen von Links zu unangemessenen Inhalten ist nicht gestattet und wird als Fremdwerbung betrachtet.</p>
        <br />
        <p><strong>§9 Multiaccount:</strong><br />
          Die Nutzung von maximal zwei Accounts ist erlaubt, jedoch ist die Regel "Pro IP 2 User" nicht gültig.</p>
        <br />
        <p><strong>§10 Benutzen von Cheating-Tools:</strong><br />
          Die Verwendung von Programmen, die einen unfairen Vorteil im Spiel verschaffen, ist strikt untersagt.</p>
        <br />
        <p><strong>§11 Verbot der Veröffentlichung und Verbreitung privater Daten:</strong><br />
          Die Veröffentlichung oder Verbreitung von privaten Kontaktdaten, Bildern oder anderen persönlichen Informationen ist streng verboten und wird mit Konsequenzen geahndet.</p>
        <br />
        <p><strong>§12 Verbotener Austausch von Echtgeld zwischen Usern:</strong><br />
          Der Handel mit digitalen Gütern gegen Echtgeld zwischen den Usern ist nicht erlaubt und wird als Verstoß gegen die Regeln angesehen.</p>
        <br />
        <p><strong>§13 Geldwäsche:</strong><br />
          Es ist nicht erlaubt, Taler, Diamanten, Duckets oder Rares durch das Erstellen mehrerer Accounts oder ähnliche Methoden zu vermehren.</p>
        <br />
        <p><strong>§14 Illegales Glücksspiel:</strong><br />
          Das Durchführen von unerlaubtem Glücksspiel ist untersagt.</p>
      </div>
    </div>
  );
}

export default Regeln;
