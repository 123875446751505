import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export const NavbarSecondaryView = (props) => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Menü açma-kapama işlemi
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Aktif sayfa kontrolü için yardımcı fonksiyon
  const isActive = (path) => location.pathname === path ? 'border-b-2 border-[#4269a1] !text-[#4269a1]' : '';

  const logout = () => {
    props.logout();
    navigate("/index", { replace: true });
  }

  const navigate = useNavigate();


  return (
    <nav className="bg-tertiary border-gray-200">
      <div className="max-w-5xl w-full flex flex-wrap items-center justify-between mx-auto p-4 md:p-0 h-auto md:h-14">
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-secondary focus:outline-none focus:ring-2 focus:bg-secondary"
          aria-controls="navbar-default"
          aria-expanded={isMenuOpen ? "true" : "false"}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`${isMenuOpen ? 'block' : 'hidden'} w-full lg:block lg:w-auto h-full`} id="navbar-default">
          <ul className="font-medium h-full flex flex-col p-4 md:p-0  items-center gap-5 lg:flex-row rtl:space-x-reverse md:mt-0 md:border-0">
            
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/me')}`}>
              <Link to="/me">Home</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/articles')}`}>
              <Link to="/articles">Neuigkeiten</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/team/all')}`}>
              <Link to="/team/all">Mitarbeiter</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/vips')}`}>
              <Link to="/vips">Premium</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/community/0')}`}>
              <Link to="/community/0">Bestenliste</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li className={`text-[#a7a7a7] h-full px-4 flex items-center justify-center text-lg ${isActive('/catalog')}`}>
              <Link to="/catalog">Preisliste</Link>
            </li>
            <li className='h-full w-0.5 bg-[#3b3b3b]'></li>
            <li onClick={() => logout()} className='text-[#cb0b0b] h-full px-4 flex items-center justify-center text-lg cursor-pointer'>
              Logout
            </li>
          </ul>
        </div>
        <div className="HabboLogo px-3 md:px-0 mx-auto md:mx-0"></div>
      </div>
    </nav>
  );
};
