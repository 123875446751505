import React, { useEffect, useState } from 'react'
import { NewUserBannerView } from './NewUserBannerView'
import PhotoHeaderView from './PhotoHeaderView'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface TopHeaderView {
    account: any
    online: number
}

export const TopHeaderView = (props: TopHeaderView) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [clientInit, setclientInit] = useState(false);
    const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible")


    useEffect(() => {
        if (location.pathname === "/play") {
            setclientInit(true);
            setVisibility("visible");
        }
        else setVisibility("hidden");

    }, [location]);

    const getSrcIframe = (sso) => "https://nitro.habbo.cm/index.html?sso=" + sso;


    return (
        <div className="flex flex-col">
            <div className="cmMainHeaders h-auto md:h-[180px] flex justify-center items-center">
                <div className="max-w-5xl w-full mx-auto flex flex-col md:flex-row justify-between items-center gap-12 p-3 md:p-0">
                    <div className="flex flex-col md:flex-row items-center gap-3">
                        <div className="cmLogo"></div>
                        <div className="flex flex-col justify-center items-center md:justify-normal md:items-start relative top-4">
                            <p className='text-2xl text-white'>Wilkommen, <b>{props.account.username}!</b></p>
                            <div className="py-1 px-3 bg-secondary w-max gap-2 text-white rounded-full flex items-center justify-between relative">
                                <p className='mr-10 text-white text-opacity-70'>{props.online} online!</p>
                                <div className="hotelIcon absolute right-0"></div>
                            </div>
                        </div>
                    </div>
                    <Link to={"/play"}>

                    <div className="py-0 px-24 mt-0 text-xl text-center text-white w-full bg-[#01a41b] border-t-[10px] rounded-t-lg border-t-[#03c304] border-b-[10px] rounded-b-lg border-b-[#01981e]">
                        Client
                    </div>
                    
                    </Link>
                </div>
            </div>
            {props.account !== null && clientInit &&
                <>
                    {visibility === "visible" && <Link className='back-button btn-sm text-center' to="/me"></Link>}

                    <div style={{overflow: "hidden"}}>
                        <iframe allow="
                            accelerometer *; 
                            ambient-light-sensor *; 
                            autoplay *; 
                            camera *; 
                            clipboard-read *; 
                            clipboard-write *; 
                            encrypted-media *; 
                            fullscreen *; 
                            geolocation *; 
                            gyroscope *; 
                            magnetometer *; 
                            microphone *; 
                            midi *; 
                            payment *; 
                            picture-in-picture *; 
                            screen-wake-lock *; 
                            speaker *; 
                            sync-xhr *; 
                            usb *; 
                            web-share *; 
                            vibrate *; 
                            vr *"
                        className='animate__animated animate__fadeIn animate__slower' 
                        src={getSrcIframe(window.localStorage.getItem("kubboch-sto"))} 
                        style={{ 
                            position: 'fixed', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            width: "100vw", 
                            height: "100vh", 
                            border: "none", 
                            margin: 0, 
                            padding: 0, 
                            overflow: "hidden !important", 
                            zIndex: 999999, 
                            visibility: visibility
                            
                         }}
                        >
                        </iframe>
                    </div>
                    </>
            }
        </div>
    )
}
