import React, { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { Link, useNavigate } from 'react-router-dom';

import { RegisterComposer } from '../../packets/RegisterComposer';
import { LocalizeText } from '../../Globals';

interface RegisterNewProps {
  texts: { [key: string]: string };
  account: any;
  online: number;
}

const RegisterNewView = (props: RegisterNewProps) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [avatar, setAvatar] = useState("ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa");
  const [genre, setGenre] = useState("M");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState("");  // New state for the selected avatar


  const navigate = useNavigate();

  useEffect(() => {
    document.title = LocalizeText(props.texts, 'register.title') + " | Habbo"
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);
      if (message.header === "registerResponse" && message.data != null) {
        if (message.data.data === "Este usuario ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.user.invalid'), "Error");
        else if (message.data.data === "Este correo ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.email.invalid'), "Error");
        else if (message.data.data === "La contraseña debe ser mayor a 6 digitos.") NotificationManager.error(LocalizeText(props.texts, 'register.password.invalid'), "Error");
        else if (message.data.data === "Ocurrio un error al crear la cuenta.") NotificationManager.error(LocalizeText(props.texts, 'register.error'), "Error");
        else {
          NotificationManager.success(LocalizeText(props.texts, 'register.success'), "OK");
          window.localStorage.setItem("kubboch-sto", message.data.data);
          navigate("/me", { replace: true });
        }
      }
    }
  }, [lastMessage, setMessageHistory]);

  const chooseAvatar = (av, genre) => {
    setAvatar(av);
    setGenre(genre);
    setSelectedAvatar(av);  // Set the selected avatar


    console.log(avatar,genre)
  }

  const chooseAccountData = () => {
    if (username === "" || email === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    document.getElementById("secondStep")?.classList.add("hide-content");
    document.getElementById("thirdStep")?.classList.remove("hide-content");
  }

  const getImagerDance = () => {
    return "https://nitro-imager.habbo.cm/?figure=" + avatar + "&direction=2&head_direction=2&size=l&dance=1&img_format=gif"
  }

  const getImagerNave = () => {
    return "https://nitro-imager.habbo.cm/?figure=" + avatar + "&direction=2&head_direction=2&size=l&effect=3&img_format=gif"
  }

  const register = () => {
    if (username === "" || email === "" || password === "" || confirmPassword === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    if (username.includes(" ")) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput2'), "Error");
      return;
    }

    if (password !== confirmPassword) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput3'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new RegisterComposer(username, email, password, avatar, genre)));
  }

  useEffect(() => {
    if (props.account !== null) navigate("/me", { replace: true });
  }, [props]);

  return (
    <>
      <div className="flex flex-col">
        <div className="cmMainHeader"></div>
        <div className="max-w-5xl w-full mx-auto flex flex-col gap-12 p-3 md:p-0">
          <div className="flex flex-col md:flex-row items-center gap-3">
            <div className="cmLogo"></div>
            <div className="flex flex-col justify-center items-center md:justify-normal md:items-start relative top-4">
              <p className='text-2xl text-white'>Wilkommen im, <b>HabboCM!</b></p>
              <div className="py-1 px-3 bg-secondary w-max gap-2 text-white rounded-full flex items-center justify-between relative">
                <p className='mr-10 text-white text-opacity-70'>{props.online} online!</p>
                <div className="hotelIcon absolute right-0"></div>
              </div>
            </div>
          </div>
          <div className="p-4 bg-tertiary rounded-lg w-full grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <div className="p-2 flex gap-2 items-center bg-primary rounded-lg">
                <div className="friendsIcon"></div>
                <div className="flex flex-col">
                  <p className='text-white text-xl font-bold relative top-1'>Register</p>
                  <p className='text-xs text-white text-opacity-50 relative top-0.5'>Do you already have an account? <Link to={"/register"}>Login</Link> now.</p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4 bg-secondary rounded-lg px-3 py-8 flex flex-col gap-4 justify-center items-center">
              <p className='text-white text-opacity-70 text-sm relative -top-3'>Choose an avatar to get started!</p>
              <div className="flex items-center justify-between w-full gap-1  max-h-[174px]">
                <div  className={`w-full px-2 py-12 bg-tertiary max-w-[143.33px] rounded-lg flex justify-center items-center relative ${selectedAvatar === "ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa" ? 'border-2 border-primary' : ''}`}>
                  <div className="maleIcon absolute top-2"></div>
                  <img onClick={() => chooseAvatar("ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa", "M")} className='choose-avatar-img' src="https://nitro-imager.habbo.cm/?figure=ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa&direction=3&head_direction=3&action=wave" />
                </div>
                <div className={`w-full px-2 py-12 bg-tertiary max-w-[143.33px] rounded-lg flex justify-center items-center relative ${selectedAvatar === "hr-8596-1e1e1e.hd-600-eea768.ch-655-ffba00.lg-695-536eb0.sh-725-ffffff" ? 'border-2 border-primary' : ''}`}>
                  <div className="femaleIcon absolute top-2"></div>
                  <img onClick={() => chooseAvatar("hr-8596-1e1e1e.hd-600-eea768.ch-655-ffba00.lg-695-536eb0.sh-725-ffffff", "F")} className='choose-avatar-img' src="https://nitro-imager.habbo.cm/?figure=hr-8596-1e1e1e.hd-600-eea768.ch-655-ffba00.lg-695-536eb0.sh-725-ffffff&direction=3&head_direction=3&action=wave" />
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-8 flex flex-col gap-4">
              <div className="flex items-center justify-between flex-wrap md:flex-nowrap w-full gap-4">
                <div className="flex flex-col gap-1 w-full">
                  <p className='text-white text-opacity-70 text-sm'>Benutzername</p>
                  <input type="text" onChange={(e) => setUsername(e.target.value)} className='cmInput  text-sm !p-3' placeholder='Benutzername...' />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <p className='text-white text-opacity-70 text-sm'>E-Mail</p>
                  <input type="email" onChange={(e) => setEmail(e.target.value)} className='cmInput  text-sm !p-3' placeholder='E-Mail...' />
                </div>
              </div>
              <div className="flex items-center justify-between flex-wrap md:flex-nowrap w-full gap-4">
                <div className="flex flex-col gap-1 w-full">
                  <p className='text-white text-opacity-70 text-sm'>Passwort</p>
                  <input type="password" onChange={(e) => setPassword(e.target.value)} className='cmInput  text-sm !p-3' placeholder='Passwort...' />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <p className='text-white text-opacity-70 text-sm'>Re-Passwort</p>
                  <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className='cmInput text-sm !p-3' placeholder='Re-Passwort...' />
                </div>
              </div>
              <div className="flex items-center justify-between flex-wrap md:flex-nowrap w-full mt-8 gap-4">
                <p className='text-white text-opacity-70 text-sm w-full'>By registering on HabboCM you declare that you accept our terms of use. </p>
                <button onClick={() => register()} className="p-1 mt-0 text-xl text-center text-white w-full bg-[#01a41b] border-t-[10px] rounded-t-lg border-t-[#03c304] border-b-[10px] rounded-b-lg border-b-[#01981e]">
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-12 bg-tertiary mt-12 md:absolute bottom-0 mb-8 md:mb-0">
          <div className="max-w-5xl w-full mx-auto flex flex-col md:flex-row items-center justify-between">
            <div className="flex flex-col md:flex-row items-center gap-2">
              <div className="cmHabboIcon"></div>
              <div className="flex flex-col text-sm text-center md:text-start">
                <a href="" className='text-white text-opacity-60'><b>Discord</b></a>
                <p className='text-white text-opacity-60'>© 2024 by habbo.cm - Developed by <b>jw7speed</b> & <b>Lariesse, Mori</b></p>
                <p className='text-white text-opacity-60'>All rights go to their respective owner(s). We are not endorsed, affiliated by Sulake Corporation.</p>
              </div>
            </div>
            <div className="flex items-center gap-4 justify-center text-white">
              <a href="">
                <i className='fa-brands fa-discord text-3xl'></i>
              </a>
              <a href="">
                <i className='fa-brands fa-tiktok text-3xl'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterNewView
