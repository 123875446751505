import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import HotelLogo from "../../assets/images/logo.png";

const FooterView = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/play") {
      setIsVisible(false);
    }
    else setIsVisible(true);

  }, [location]);

  return (
    <>
      {isVisible &&
        <>
          <div className="w-full p-12 bg-tertiary mt-12">
            <div className="max-w-5xl w-full mx-auto flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="cmHabboIcon"></div>
                <div className="flex flex-col text-sm">
                  <a href="" className='text-white text-opacity-60'><b>Discord</b></a>
                  <p className='text-white text-opacity-60'>© 2024 by habbo.cm - Developed by <b>jw7speed</b> & <b>Lariesse, Mori</b></p>
                  <p className='text-white text-opacity-60'>All rights go to their respective owner(s). We are not endorsed, affiliated by Sulake Corporation.</p>
                </div>
              </div>
              <div className="flex items-center gap-4 justify-center text-white">
                <a href="">
                  <i className='fa-brands fa-discord text-3xl'></i>
                </a>
                <a href="">
                  <i className='fa-brands fa-tiktok text-3xl'></i>
                </a>
              </div>
            </div>
          </div>
        </>
      }

    </>
  )
}

export default FooterView
