import React, { FC, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { UpdatePriceRareComposer } from '../../../packets/UpdatePriceRareComposer';
import CatalogItemUserView from './CatalogItemUserView';

interface ModalCatalogItemProps {
    item: any;
    canEdit: boolean;
    onClose: () => void;
}

const ModalCatalogItemView: FC<ModalCatalogItemProps> = ({ item, canEdit, onClose }) => {
    const [editVisible, setEditVisible] = useState(false);
    const [credits, setCredits] = useState(0);
    const [diamonds, setDiamonds] = useState(0);

    const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.one:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    useEffect(() =>{
        setCredits(item.credits)
        setDiamonds(item.diamonds)
    }, [])


    const toggleEdit = () => {
        setEditVisible(!editVisible);
    }

    const updatePrice = () => {
        if (credits != null && diamonds != null) {
            sendMessage(JSON.stringify(new UpdatePriceRareComposer(item.id, credits, diamonds)));
            toggleEdit();
        }
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
            <div className="bg-dark text-white rounded-lg shadow-lg p-4 relative max-w-lg w-full bg-secondary flex flex-col">
                <div className="flex p-3 rounded-xl bg-primary justify-between items-center">
                    <p>{item.name}</p>
                    <button onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className='cmPreislisteBackground h-40 mt-8 justify-content-center !w-[318px] mx-auto' style={{ height: "159px" }}>
                    {canEdit ?
                        editVisible ? 
                            <div className='flex gap-4 z-50 mb-4 absolute left-4 -mt-3 w-full'>
                                <button className="btn btn-warning btn-sm px-4 py-2 bg-secondary rounded-md bg-opacity-50" onClick={updatePrice}>Save</button>
                                <button className="btn btn-warning btn-sm px-4 py-2 bg-secondary rounded-md bg-opacity-50" onClick={toggleEdit}>Cancel</button>
                            </div>
                            :
                            <button className="btn btn-warning btn-sm px-4 py-2 bg-secondary rounded-md bg-opacity-50 absolute left-4 -mt-3" onClick={toggleEdit}>Edit</button>
                        :
                        <></>
                    }
                    <div className='h-full w-full bg-no-repeat bg-center !-mt-5' style={{ backgroundImage: `url(${item.image})` }}/>
                </div>
                {canEdit && editVisible ? (
                    <div className="flex flex-col md:flex-row gap-1 mt-2 items-center">
                        <div className="flex items-center justify-center gap-2 w-full p-2 bg-secondary bg-opacity-50 rounded-md">
                            <input className='catalog-furni-price catalog-item-input_edit !bg-primary' value={credits} type='number' onChange={(e) => setCredits(Number(e.target.value))} />
                            <img src="https://images.habbo.one/images/wallet/-1.png" alt="credits" />
                        </div>
                        <div className="flex items-center justify-center gap-2 w-full p-2 bg-secondary bg-opacity-50 rounded-md">
                            <input className='catalog-furni-price catalog-item-input_edit !bg-primary' value={diamonds} type='number' onChange={(e) => setDiamonds(Number(e.target.value))}  />
                            <img src="https://images.habbo.one/images/wallet/5.png" alt="diamonds" />
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-1 mt-3">
                        <div className="w-full rounded-lg flex items-center justify-center gap-2 text-sm bg-[#ffc000] p-2 text-black">
                            {item.price_credits == 1 ? "Unbekannt" : item.price_credits}
                            <img src="https://images.habbo.one/images/wallet/-1.png" alt="credits" />
                        </div>
                        <div className="w-full rounded-lg flex items-center justify-center gap-2 text-sm bg-[#4aa78f] p-2 text-black">
                        {item.price_diamonds == 1 ? "Unbekannt" : item.price_diamonds}
                            <img src="https://images.habbo.one/images/wallet/5.png" alt="credits" />
                        </div>
                    </div>
                )}
                <span className="tracking-widest text-md uppercase text-center mt-2">Im Besitz:</span>
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-2 px-2 h-[9.4rem] overflow-hidden overflow-y-auto">
                    {item.users.length != 0 && item.users.map(x =>
                            <CatalogItemUserView key={x.username} look={x.look} username={x.username} quantity={x.quantity} />
                        )}
                </div>
                <button className="btn btn-danger w-full mt-2" onClick={onClose}>Schließen</button>
            </div>
        </div>
    )
}

export default ModalCatalogItemView;
