class Data{
    id: number;
    credits: number;
    diamonds: number;

    constructor(id: number, credits: number, diamonds: number){
        this.id = id;
        this.credits = credits;
        this.diamonds = diamonds;
    }
}

export class UpdatePriceRareComposer{
    header: string;
    data: Data;

    constructor(id: number, credits: number, diamonds: number){
        this.header = "updateRarePrice";
        this.data = new Data(id, credits, diamonds);
    }
}